export const getUploadUrlQuery =  `
query getUploadUrl (
  $sow: ID,
  $key: String,
  $expires: Int,
  $type: String
) {
  getUploadUrl(
    sow: $sow
    key: $key
    expires: $expires
    type: $type
  )
}
`