import { call, put, takeEvery, takeLatest, delay } from 'redux-saga/effects'
import * as Sentry from "@sentry/react";

import { actions as NotificationActions } from '../slices/notification'

export function* sagas() {
  try {
    yield takeLatest(NotificationActions.willShowNotification.type, willShowNotification)
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* willShowNotification(action: any) {
  console.log('in willShowNotification function');
  yield delay(action.payload.delay ? action.payload.delay : 3000)
  yield put(NotificationActions.willHideNotification())
}
