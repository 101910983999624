export const deleteAttachmentQuery =  `
mutation deleteAttachment(
  $key: String, 
  $sow: ID
) {
  deleteAttachment(
    key: $key
    sow: $sow
  ) {
    deleted
    error
  }
}
`