import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Row, Card, CardBody, CardTitle, Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter,
  ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
  FormGroup, Label, Input, Jumbotron, CardSubtitle, CardText
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode, faKey } from '@fortawesome/free-solid-svg-icons'
import qrcode from 'qrcode-generator';
import { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';
import { Dispute } from '@trustshare/react-sdk';

import { configuration } from '../../config'
import { actions as SowActions, selectors as SowSelectors, SowCommands } from '../../store/slices/sow'
import { actions as ChatActions, selectors as ChatSelectors } from '../../store/slices/chat'
import { selectors as ArbitratorSelectors } from '../../store/slices/arbitrator'
import { actions as TransactionActions, selectors as TransactionSelectors } from '../../store/slices/transaction'
import { actions as NotificationActions } from '../../store/slices/notification'
import { actions as ProfileActions, selectors as ProfileSelectors } from '../../store/slices/profile'
import { actions as EscrowActions, selectors as EscrowSelectors } from '../../store/slices/escrow'
import { actions as UIActions } from '../../store/slices/ui'
import { selectors as AuthSelectors } from '../../store/slices/auth'
import { SaveMnemonicModal } from '../profile/SaveMnemonic'
import { ActivityButton } from '../common/ActivityButton';
import { Payment } from './Payment'
const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

export const RaiseDispute = ({ modal, toggle }: any) => {

  const dispatch = useDispatch();
  const user = useSelector(AuthSelectors.getUser)
  const { t, i18n } = useTranslation();
  const currentSow = useSelector(SowSelectors.getCurrentSow)
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const messagesCommands = useSelector(ChatSelectors.getMessagesCommands)
  const currentChosenArbitrator = useSelector(ArbitratorSelectors.getCurrentChosenArbitrator)
  const transactionPage = useSelector(TransactionSelectors.getTransactionPage)
  const transactionError = useSelector(TransactionSelectors.getError)
  const newAttachments = useSelector(SowSelectors.getNewAttachments)
  const users = useSelector(ProfileSelectors.getUsers)
  const escrow = useSelector(EscrowSelectors.getEscrow)
  const isEscrowCompleted = useSelector(EscrowSelectors.isEscrowCompleted)

  const [acceptedConditions, setAcceptedConditions] = React.useState(false);

  React.useEffect(() => {
    modal && dispatch(TransactionActions.goToTransactionPage({ transactionPage: 1, sowCommand: SowCommands.RAISE_ESCROW_DISPUTE }))

    return () => {
      setAcceptedConditions(false)
      dispatch(TransactionActions.goToTransactionPage({ transactionPage: 0, sowCommand: SowCommands.RAISE_ESCROW_DISPUTE }))
      dispatch(EscrowActions.setFalseIsEscrowCompleted())
    }
  }, [modal])

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl" backdrop={"static"} data-cy='acceptAndPayModal' scrollable={true}>
      {transactionPage[SowCommands.RAISE_ESCROW_DISPUTE] == 0 &&
        <>
          <ModalHeader toggle={toggle}>{t(`chat.SowCommands.${SowCommands.RAISE_ESCROW_DISPUTE}`)}</ModalHeader>
          <ModalBody className="text-center">
            <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
          </ModalBody>
        </>
      }
      {transactionPage[SowCommands.RAISE_ESCROW_DISPUTE] == 1 &&
        <>
          <ModalHeader toggle={toggle}>Raise dispute</ModalHeader>
          <ModalBody>
            <Jumbotron name="conditions" id="conditions">
              <CardText name="transactionConditions">{t('transaction.raiseDisputeConditions')}</CardText>
            </Jumbotron>
            {/* <FormGroup check>
              <Label check>
                <Input data-cy="acceptConditions" checked={acceptedConditions} name="acceptConditions" id="acceptConditions" type="checkbox"
                  onChange={(event) => setAcceptedConditions(event.target.checked)}
                />Accept conditions *
              </Label>
            </FormGroup> */}

          </ModalBody>
          <ModalFooter>
            <ActivityButton name="cancelTransaction" color="primary" outline onClick={() => {
              toggle()
            }}>Cancel</ActivityButton>
            <ActivityButton data-cy='continueTransaction' name="continueTransaction" color="primary" onClick={() => {
              dispatch(TransactionActions.goToTransactionPage({ transactionPage: 10, sowCommand: SowCommands.RAISE_ESCROW_DISPUTE }))
            }}>Continue</ActivityButton>
          </ModalFooter>
        </>
      }
      {transactionPage[SowCommands.RAISE_ESCROW_DISPUTE] == 10 &&
        <>
          <ModalHeader toggle={toggle}>Escrow</ModalHeader>
          <ModalBody className="d-flex justify-content-center">
            <Dispute
              subdomain={configuration[stage].Trustshare_domain}
              token={JSON.parse(messagesCommands[SowCommands.ACCEPT_AND_PAY].commandMessage.data).token}
              onUpdate={(event: any) => {
                console.log("Dispute onUpdate", event);
                dispatch(EscrowActions.willUpdateEscrowDispute({ escrow: event, sow: currentSow.sow }))
              }}
              onComplete={(event: any) => {
                console.log("Dispute onComplete", event);
                dispatch(EscrowActions.willCompleteEscrowDispute({ escrow: event, currentSow: currentSow }))
              }}
            />
          </ModalBody>
          {isEscrowCompleted &&
            <ModalFooter>
              <ActivityButton data-cy='closeEscrow' name="closeEscrow" color="primary" onClick={() => {
                escrow.status === "dispute_raised" ?
                  dispatch(TransactionActions.goToTransactionPage({ transactionPage: 6, sowCommand: SowCommands.RAISE_ESCROW_DISPUTE }))
                  : dispatch(TransactionActions.goToTransactionPage({ transactionPage: 7, sowCommand: SowCommands.RAISE_ESCROW_DISPUTE }))
              }}>Close</ActivityButton>
            </ModalFooter>
          }
        </>
      }
      {
        transactionPage[SowCommands.RAISE_ESCROW_DISPUTE] == 6 &&
        <>
          <ModalHeader toggle={toggle}>Dispute raised</ModalHeader>
          <ModalBody>
            <Jumbotron>
              <CardText>
                The dispute was raised succesfully.
              </CardText>
            </Jumbotron>
          </ModalBody>
          <ModalFooter>
            <ActivityButton data-cy="closeAcceptAndPay" name="closeTransaction" color="primary" onClick={() => {
              dispatch(SowActions.willGetSow({ sow: currentSow.sow }))
              toggle()
            }
            }
            >Close</ActivityButton>
          </ModalFooter>
        </>
      }
      {
        transactionPage[SowCommands.RAISE_ESCROW_DISPUTE] == 7 &&
        <>
          <ModalHeader toggle={toggle}>Dispute failed</ModalHeader>
          <ModalBody>
            <Jumbotron>
              <CardText>
                The dispute failed, retry.
              </CardText>
            </Jumbotron>
          </ModalBody>
          <ModalFooter>
            <ActivityButton name="closeTransaction" color="primary" onClick={toggle}>Close</ActivityButton>
          </ModalFooter>
        </>
      }
    </Modal >
  )
}