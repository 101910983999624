export const getDownloadUrlQuery =  `
query getDownloadUrl (
  $sow: ID,
  $key: String,
  $expires: Int,
  $versionId: String
) {
  getDownloadUrl(
    sow: $sow
    key: $key
    expires: $expires
    versionId: $versionId
  )
}
`