import {
  CardText, CardTitle, Container, Jumbotron
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

export const TermsOfServicePage = () => {

  const { t, i18n } = useTranslation();

  return (
    <>
      <Container>
        <CardTitle className="h5 text-center font-weight-bold">
          Terms of Service
          </CardTitle>
        <Jumbotron style={{height: "95%"}} className="d-flex justify-content-center">
          <iframe width="80%" height="100%" 
            src="https://docs.google.com/document/d/e/2PACX-1vRa_B6AsdNcjvXq8I6hsfBMGg04b_OLH7bIyCYP8afLhc9FfU5xVp3X0uWt8-Hmzg/pub?embedded=true" />
        </Jumbotron>
      </Container>
    </>
  )
}