export const submitSowQuery =  `
mutation submitSow(
  $sow: ID,
  # $arbitrators: [String],
  $codeOfConduct: Boolean,
  $currency: Currency,
  $buyer: String,
  $deadline: AWSDateTime,
  $description: String,
  $numberReviews: Int,
  $price: Float,
  $quantity: Int,
  $tags: [String],
  $termsOfService: Boolean,
  $title: String,
  $sowExpiration: Int,
  $licenseTermsOption: String,
  $licenseTermsNotes: String
) {
  submitSow(
    sow: $sow
    # arbitrators: $arbitrators,
    codeOfConduct: $codeOfConduct,
    currency: $currency,
    buyer: $buyer,
    deadline: $deadline,
    description: $description,
    numberReviews: $numberReviews,
    price: $price,
    quantity: $quantity,
    tags: $tags,
    termsOfService: $termsOfService,
    title: $title,
    sowExpiration: $sowExpiration,
    licenseTermsOption: $licenseTermsOption,
    licenseTermsNotes: $licenseTermsNotes
  ) {
    sow
    # arbitrators
    codeOfConduct
    currency
    seller
    buyer
    deadline
    description
    numberReviews
    price
    quantity
    tags
    termsOfService
    title
    createdAt
    updatedAt
    sowExpiration
    submittedDate
    licenseTermsOption
    licenseTermsNotes
  }
}
 `