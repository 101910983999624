export const listSowChatMessagesQuery =  `
query listSowChatMessages(
  $sow: ID
  $after: AWSDateTime
){
  listSowChatMessages(
    sow: $sow
    after: $after
  ){
    messages {
      createdAt
      sow
      messageId
      textMessage {
        message
      }
      attachmentMessage {
        key
        size
        type
      }
      commandMessage {
        command
        data
      }
      type
      modified
      from
    }
    nextToken
  }
}
 `