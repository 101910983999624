import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import update from 'immutability-helper';

export const currentSlice = createSlice({
  name: 'escrow',
  initialState: {
    isEscrowCompleted: false,
    escrow: {} as any,
    widgetData: {} as any
  },
  reducers: {
    setFalseIsEscrowCompleted: (state, action: PayloadAction<any>) => void (state.isEscrowCompleted = false),

    willUpdateEscrowCheckout: (state, action: PayloadAction<any>) => state,
    didUpdateEscrowCheckout: (state, action: PayloadAction<any>) => void (state.escrow = action.payload.escrow),
    willCompleteEscrowCheckout: (state, action: PayloadAction<any>) => state,
    didCompleteEscrowCheckout: (state, action: PayloadAction<any>) => void (state.isEscrowCompleted = true, state.escrow = action.payload.escrow),


    willUpdateEscrowRelease: (state, action: PayloadAction<any>) => state,
    didUpdateEscrowRelease: (state, action: PayloadAction<any>) => void (state.escrow = action.payload.escrow),
    willCompleteEscrowRelease: (state, action: PayloadAction<any>) => state,
    didCompleteEscrowRelease: (state, action: PayloadAction<any>) => void (state.isEscrowCompleted = true, state.escrow = action.payload.escrow),


    willUpdateEscrowDispute: (state, action: PayloadAction<any>) => state,
    didUpdateEscrowDispute: (state, action: PayloadAction<any>) => void (state.escrow = action.payload.escrow),
    willCompleteEscrowDispute: (state, action: PayloadAction<any>) => state,
    didCompleteEscrowDispute: (state, action: PayloadAction<any>) => void (state.isEscrowCompleted = true, state.escrow = action.payload.escrow),

    /// WIDGET
    didSetWidgetData: (state, action: PayloadAction<any>) => void (state.isEscrowCompleted = true, state.escrow = action.payload.escrow),
  }
})

export const { actions, reducer }: any = currentSlice
export const {
  setFalseIsEscrowCompleted,
  willUpdateEscrowCheckout, willCompleteEscrowCheckout,
  willUpdateEscrowRelease, willCompleteEscrowRelease,
  willUpdateEscrowDispute, willCompleteEscrowDispute
} = actions
export const selectors = {
  isEscrowCompleted: (state: any) => state.escrow.isEscrowCompleted,
  getEscrow: (state: any) => state.escrow.escrow,
  getWidgetData: (state: any) => state.escrow.widgetData,
}
