import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import { history } from './store'
import { ConnectedRouter } from 'connected-react-router'

import { Header } from './header'
import { Footer } from './footer'
import { HomePage } from './pages/home'

import { LoginPage } from './pages/login'
import { SignupPage } from './pages/signup'
import { SignupConfirmPage } from './pages/signupConfirm'
import { ProfilePage } from './pages/profile'
import { CreateAlgoAccountPage } from './pages/createAlgoAccount'
import { CreateStatementOfWorkPage } from './pages/createStatementOfWork'
import { StatementOfWorkPage } from './pages/statementOfWork'
import { ArbitratorsPage } from './pages/arbitrators'
import { OptinAssetPage } from './pages/optinAssets'
import { TermsOfServicePage } from './pages/termsOfService'
import { ContactUsPage } from './pages/contactUs'
import { CookiePolicyPage } from './pages/cookiePolicy'
import { PrivacyPolicyPage } from './pages/privacyPolicy'
import { MailIntegrationPage } from "./pages/mailIntegration"
import backgroundLogin from './images/pageLogin.svg'
import backgroundGeneral from './images/sfondi.svg'

import { selectors as AuthSelectors } from './store/slices/auth'

const PrivateRoute = ({ children, ...rest }: any) => {

  let history = useHistory();
  const isLogged = useSelector(AuthSelectors.isLogged)
  const isChecked = useSelector(AuthSelectors.isChecked)

  return (
    <Route
      {...rest}
      render={
        props => {
          console.log("isLogged: ", isLogged)
          return (
            <>
              {isChecked ?
                (
                  <>
                    {isLogged ?
                      (
                        children
                      )
                      :
                      <Redirect to="/login" />
                    }
                  </>
                )
                :
                (
                  <p>waiting</p>
                )
              }
            </>
          )
        }
      }
    />
  )
}

export const AppRouter = () => {

  console.log("ROUTER history: ", history)

  return (
    <ConnectedRouter history={history}>
      <Wrap />
    </ConnectedRouter>
  );
}


const Wrap = ({ children, ...rest }: any) => {

  let history = useHistory();
  console.log("Wrap history: ", history)

  return (
    <div style={{ backgroundImage: (history.location.pathname === "/login" ? `url(${backgroundLogin})` : `url(${backgroundGeneral})`), backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
      <Header className="sticky-top shadow" />
      <Container className="d-flex pt-3" style={{ minHeight: 'calc(100vh - 91px - 130px)' }}>
        <Switch>
          {/* <Route path="/about">
            <About />
          </Route> */}
          <Route path="/login">
            <LoginPage />
          </Route>

          <Route path="/sales">
            <HomePage />
          </Route>

          <Route path="/purchases">
            <HomePage tab="2" />
          </Route>

          <Route path="/signup/confirm/:code">
            <SignupConfirmPage />
          </Route>
          <Route path="/signup/confirm/">
            <SignupConfirmPage />
          </Route>
          <Route path="/signup">
            <SignupPage />
          </Route>
          {/* <Route path="/users">
            <Users />
          </Route> */}
          {/* <Route path="/arbitrators">
            <ArbitratorsPage />
          </Route> */}
          {/* <Route path="/cookie-policy">
            <CookiePolicyPage />
          </Route> */}
          <Route path="/privacy-policy">
            <PrivacyPolicyPage />
          </Route>
          <Route path="/terms-of-service">
            <TermsOfServicePage />
          </Route>
          <Route path="/contact-us">
            <ContactUsPage />
          </Route>
          {/* <PrivateRoute path="/optin-assets">
            <OptinAssetPage />
          </PrivateRoute> */}
          <PrivateRoute path="/profile">
            <ProfilePage />
          </PrivateRoute>
          <PrivateRoute path="/mail-integration">
            <MailIntegrationPage />
          </PrivateRoute>
          {/* <PrivateRoute path="/create-algo-account">
            <CreateAlgoAccountPage />
          </PrivateRoute> */}
          <PrivateRoute path="/create-statement-of-work">
            <CreateStatementOfWorkPage />
          </PrivateRoute>
          <PrivateRoute path="/statement-of-work/:code">
            <StatementOfWorkPage />
          </PrivateRoute>
          <PrivateRoute path="/" >
            <HomePage />
          </PrivateRoute>
        </Switch>
      </Container>
      <Footer className="mt-3" />
    </div>
  )
}