export const algorandGetTxParamsWithDelayQuery =  
`query algorandGetTxParamsWithDelay{
  algorandGetTxParamsWithDelay {
    flatFee
    fee
    firstRound
    lastRound
    genesisID
    genesisHash
    error
  }
}
`