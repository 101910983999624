import { useDispatch, useSelector } from "react-redux";
import {
  Row, Col, CardText, CardTitle, Container, Jumbotron
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { selectors as ProfileSelectors } from '../store/slices/profile'

export const MailIntegrationPage = () => {

  const { t, i18n } = useTranslation();
  const userAttributes = useSelector(ProfileSelectors.getProfile)

  return (
    <>
      <Container>
        <CardTitle className="h5 text-center font-weight-bold">
          Mail integration
        </CardTitle>
        <Jumbotron className="m-3 p-3">
          <Row className="text-center">
            <Col>
              <CardText tag="h5">
                {t('profile.mailIntegration')}
              </CardText>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex justify-content-center">
              {/* web */}
              <CardText tag="h4" className="d-none d-lg-block" >
                <a target="_blank" href={`mailto:${userAttributes.uniscrow_email}`}>{userAttributes.uniscrow_email}</a>
              </CardText>
              {/* mobile */}
              <CardText tag="h4" className="d-md-none" style={{ fontSize: 14 }} >
                <a target="_blank" href={`mailto:${userAttributes.uniscrow_email}`}>{userAttributes.uniscrow_email}</a>
              </CardText>
            </Col>
          </Row>
        </Jumbotron>
      </Container>
    </>
  )
}