export const algorandFinalizeTransactionQuery =  
`mutation algorandFinalizeTransaction(
  $hash_round: String, 
  $round_sow: String, 
  $tx:[TransactionInput]
) {
  algorandFinalizeTransaction(
    hash_round: $hash_round,
    round_sow: $round_sow
    tx: $tx
  )
}
`