import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Row, Card, CardBody, CardTitle, Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter,
  ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
  FormGroup, Label, Input, Jumbotron, CardSubtitle, CardText
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode, faKey } from '@fortawesome/free-solid-svg-icons'
import qrcode from 'qrcode-generator';
import { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';
import { Checkout } from '@trustshare/react-sdk';

import { configuration } from '../../config'
import { actions as SowActions, selectors as SowSelectors, SowCommands } from '../../store/slices/sow'
import { actions as ChatActions, selectors as ChatSelectors } from '../../store/slices/chat'
import { selectors as ArbitratorSelectors } from '../../store/slices/arbitrator'
import { actions as TransactionActions, selectors as TransactionSelectors } from '../../store/slices/transaction'
import { actions as NotificationActions } from '../../store/slices/notification'
import { actions as ProfileActions, selectors as ProfileSelectors } from '../../store/slices/profile'
import { actions as EscrowActions, selectors as EscrowSelectors } from '../../store/slices/escrow'
import { actions as UIActions } from '../../store/slices/ui'
import { selectors as AuthSelectors } from '../../store/slices/auth'
import { SaveMnemonicModal } from '../profile/SaveMnemonic'
import { SowDetails } from '../sow/SowDetails'
import { ActivityButton } from '../common/ActivityButton';
import { Payment } from './Payment'
const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

export const AcceptAndPay = ({ modal, toggle }: any) => {

  const dispatch = useDispatch();
  const user = useSelector(AuthSelectors.getUser)
  let saveMnemonicLS: any = localStorage.getItem('saveMnemonic')
  let saveMnemonicParsed = saveMnemonicLS ? JSON.parse(saveMnemonicLS) : undefined
  const saveMnemonicMy = saveMnemonicParsed ? saveMnemonicParsed[user.username] : undefined
  const { t, i18n } = useTranslation();
  const currentSow = useSelector(SowSelectors.getCurrentSow)
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const messagesCommands = useSelector(ChatSelectors.getMessagesCommands)
  const currentChosenArbitrator = useSelector(ArbitratorSelectors.getCurrentChosenArbitrator)
  const transactionPage = useSelector(TransactionSelectors.getTransactionPage)
  const multiSig = useSelector(TransactionSelectors.getMultiSig)
  const transactionAcceptAndPay = useSelector(TransactionSelectors.getTransactionAcceptAndPay)
  const transactionError = useSelector(TransactionSelectors.getError)
  const params = useSelector(TransactionSelectors.getParams)
  const payment = useSelector(TransactionSelectors.getPayment)
  const newAttachments = useSelector(SowSelectors.getNewAttachments);
  // const algoSigner = useSelector(TransactionSelectors.getAlgoSigner)
  const users = useSelector(ProfileSelectors.getUsers)
  const escrow = useSelector(EscrowSelectors.getEscrow)
  const isEscrowCompleted = useSelector(EscrowSelectors.isEscrowCompleted)

  const [acceptedConditions, setAcceptedConditions] = React.useState(false);
  const [acceptedTermsOfService, setAcceptedTermsOfService] = React.useState(false);
  const [mnemonicSecretKey, setMnemonicSecretKey] = React.useState('');
  const [passphrase, setPassphrase] = React.useState('');
  const [saveMnemonicAsk, setSaveMnemonicAsk] = React.useState(false);

  // const typeNumber: TypeNumber = 4;
  // const errorCorrectionLevel: ErrorCorrectionLevel = 'L';
  // const qr = qrcode(typeNumber, errorCorrectionLevel);
  // qr.make();
  // const [qrImg, SetQrImg] = React.useState(qr.createDataURL(10, 1));

  // const subscribeOnAmountChecked = () => {
  //   const subscription = loader('../../graphql/onAmountChecked.gql')
  //   console.log("in onAmountChecked id: ", currentSow.sow)

  //   const result: any = (API.graphql(graphqlOperation(subscription, { id: currentSow.sow })) as any)
  //     .subscribe({
  //       next: ({ provider, value }: any) => {
  //         console.log("onAmountChecked received subscribe with ", value);

  //         if (value.data.onAmountChecked.status === "AMOUNT_OK") {
  //           console.log("onAmountChecked value success: ", value)
  //           dispatch(TransactionActions.willSetSowArbitrator({ sow: currentSow.sow, arbitrator: currentChosenArbitrator }))
  //           dispatch(TransactionActions.didCompleteTransactionAcceptAndPay({ tx: value.data, sowCommand: SowCommands.ACCEPT_AND_PAY }))
  //         }
  //         else if (value.data.onAmountChecked.status === "AMOUNT_NOT_OK") {
  //           console.log("onAmountChecked value fail: ", value)
  //           dispatch(TransactionActions.didCompleteTransactionAcceptAndPayFail("Not enough balance on the wallet."))
  //           dispatch(NotificationActions.willShowNotification({ message: "Not enough balance on the wallet", type: "danger" }));
  //         }

  //         dispatch(UIActions.stopActivityRunning('willCompleteTransactionAcceptAndPayQR'));
  //         dispatch(UIActions.stopActivityRunning('willCompleteTransactionAcceptAndPayAlgoSigner'));
  //         dispatch(SowActions.willGetSow({ sow: currentSow.sow }))
  //       }
  //     });
  // }

  // React.useEffect(() => {
  //   if (multiSig.address) {
  //     qr.addData(multiSig.address);
  //     qr.make();
  //     SetQrImg(qr.createDataURL(10, 1))
  //   }
  // }, [multiSig])

  // const [isAlgoSignInstalled, setAlgo] = React.useState(false);
  // React.useEffect(() => {
  //   if (transactionPage[SowCommands.ACCEPT_AND_PAY] == 2) {
  //     if (typeof AlgoSigner !== 'undefined') {
  //       setAlgo(true);
  //     }
  //   }
  // }, [transactionPage]);

  React.useEffect(() => {
    // modal && dispatch(TransactionActions.willGetParams({ seller: currentSow.seller, buyer: currentSow.buyer, arbitrator: currentChosenArbitrator, sowCommand: SowCommands.ACCEPT_AND_PAY }))
    modal && dispatch(TransactionActions.goToTransactionPage({ transactionPage: 1, sowCommand: SowCommands.ACCEPT_AND_PAY }))

    return () => {
      setAcceptedConditions(false)
      setAcceptedTermsOfService(false)
      // setMnemonicSecretKey('')
      dispatch(TransactionActions.goToTransactionPage({ transactionPage: 0, sowCommand: SowCommands.ACCEPT_AND_PAY }))
      dispatch(EscrowActions.setFalseIsEscrowCompleted())
    }
  }, [modal])

  // React.useEffect(() => {
  //   (transactionPage[SowCommands.ACCEPT_AND_PAY] == 3/*  || transactionPage[SowCommands.ACCEPT_AND_PAY] == 5 */) && subscribeOnAmountChecked()
  // }, [transactionPage])

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl" backdrop={"static"} data-cy='acceptAndPayModal' scrollable={true}>
      {transactionPage[SowCommands.ACCEPT_AND_PAY] == 0 &&
        <>
          <ModalHeader toggle={toggle}>{t(`chat.SowCommands.${SowCommands.ACCEPT_AND_PAY}`)}</ModalHeader>
          <ModalBody className="text-center">
            <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
          </ModalBody>
        </>
      }
      {transactionPage[SowCommands.ACCEPT_AND_PAY] == 1 &&
        <SowDetails modal={modal} toggle={toggle} sowCommand={SowCommands.ACCEPT_AND_PAY} />
      }
      {transactionPage[SowCommands.ACCEPT_AND_PAY] == 2 &&
        <>
          <ModalHeader toggle={toggle}>Accept the conditions</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input data-cy="acceptConditions" checked={acceptedConditions} name="acceptConditions" id="acceptConditions" type="checkbox"
                      onChange={(event) => setAcceptedConditions(event.target.checked)}
                    />I’ve read and accept the Statement of Work proposed by the seller, including the specifications and the additional terms and conditions attached to it. *
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input checked={acceptedTermsOfService} name="acceptTermsOfService" id="acceptTermsOfService" type="checkbox"
                      onChange={(event) => setAcceptedTermsOfService(event.target.checked)}
                    />I’ve read and accept the general <a target="_blank" href="/terms-of-service" color="link">Terms of Service</a> of Uniscrow. I understand that funds will be locked into an escrow account and will be unlocked favour of the seller once the proof of delivery is accepted. *
                  </Label>
                </FormGroup>
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <ActivityButton name="cancelTransaction" color="primary" outline onClick={() => {
              dispatch(TransactionActions.goToTransactionPage({ transactionPage: 1, sowCommand: SowCommands.ACCEPT_AND_PAY }))
            }}>Cancel</ActivityButton>
            <ActivityButton data-cy='continueTransaction' disabled={!acceptedConditions || !acceptedTermsOfService} name="continueTransaction" color="primary" onClick={() => {
              // dispatch(TransactionActions.willCreateMultiSigAddress({ seller: currentSow.seller, buyer: currentSow.buyer, arbitrator: currentSow.arbitrator, price: currentSow.price, currency: currentSow.currency, sowCommand: SowCommands.ACCEPT_AND_PAY }))
              dispatch(TransactionActions.goToTransactionPage({ transactionPage: 10, sowCommand: SowCommands.ACCEPT_AND_PAY }))
            }}>Continue</ActivityButton>
          </ModalFooter>
        </>
      }
      {transactionPage[SowCommands.ACCEPT_AND_PAY] == 10 &&
        <>
          <ModalHeader toggle={toggle}>Escrow</ModalHeader>
          <ModalBody className="d-flex justify-content-center">
            <Checkout
              subdomain={configuration[stage].Trustshare_domain}
              // format="popup"
              to={users[currentSow.seller].email}
              from={users[currentSow.buyer].email}
              amount={currentSow.price * 100}
              // depositAmount="100000"
              currency={currentSow.currency.toLowerCase()}
              description={'UNISCROW - "' + currentSow.title + '"'}
              onUpdate={(event: any) => {
                console.log("escrow onUpdate", event);
                dispatch(EscrowActions.willUpdateEscrowCheckout({ escrow: event, sow: currentSow.sow }))
              }}
              onComplete={(event: any) => {
                console.log("escrow onComplete", event);
                dispatch(EscrowActions.willCompleteEscrowCheckout({ escrow: event, currentSow: currentSow }))
              }}
            />
          </ModalBody>
          {isEscrowCompleted &&
            <ModalFooter>
              <ActivityButton data-cy='closeEscrow' disabled={!acceptedConditions} name="closeEscrow" color="primary" onClick={() => {
                dispatch(TransactionActions.goToTransactionPage({ transactionPage: 6, sowCommand: SowCommands.ACCEPT_AND_PAY }))
              }}>Close</ActivityButton>
            </ModalFooter>
          }
        </>
      }
      {/* {transactionPage[SowCommands.ACCEPT_AND_PAY] == 2 &&
        <>
          <ModalHeader toggle={toggle}>Choose the method to sign</ModalHeader>
          <ModalBody>
            <CardSubtitle tag="h6" className="py-1 text-muted text-center">You are signing the quote and committing to receive the service as described in the <a target="_blank" href={newAttachments.find((file: any) => file.filename === "works_agreement.pdf").downloadUrl}>works agreement</a>.</CardSubtitle>
            <CardSubtitle tag="h6" className="py-1 text-muted text-center">Furthermore, you are explicitly opt-in to receive the asset <a target="_blank" href={configuration[stage].AlgoExplorer_link["asset"] + JSON.parse(messagesCommands[SowCommands.SUBMIT].commandMessage.data).assetId}>{JSON.parse(messagesCommands[SowCommands.SUBMIT].commandMessage.data).assetId}</a>.</CardSubtitle>
            <Payment />

            <Row> */}
      {/* <Col>
                  <Card outline onClick={() => {
                    dispatch(TransactionActions.willCompleteTransactionAcceptAndPayQR({ multiSigAddress: multiSig.address, total: payment.total, sow: currentSow.sow }))
                  }}>
                    <CardBody className="text-center">
                      <CardSubtitle tag="h5" className="mb-2 text-muted text-center">QR</CardSubtitle>
                      <FontAwesomeIcon icon={faQrcode} size="5x" />
                    </CardBody>
                  </Card>
                </Col> */}
      {/* <Col>
                <Card data-cy='acceptAndPay' onClick={() => {
                  dispatch(TransactionActions.goToTransactionPage({ transactionPage: 4, sowCommand: SowCommands.ACCEPT_AND_PAY }))
                }}>
                  <CardBody className="text-center">
                    <CardSubtitle tag="h5" className="mb-2 text-muted text-center">Mnemonic</CardSubtitle>
                    <FontAwesomeIcon icon={faKey} size="5x" />
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card onClick={() => { */}
      {/* // isAlgoSignInstalled ? dispatch(TransactionActions.willPrepareAlgoSigner({ sowCommand: SowCommands.ACCEPT_AND_PAY }))
                  //   : dispatch(NotificationActions.willShowNotification({ message: "Please install AlgoSigner", type: "info" }));
                  dispatch(NotificationActions.willShowNotification({ message: "In development", type: "info" }));
                }}>
                  <CardBody className={isAlgoSignInstalled ? "text-center" : "text-center text-muted"}>
                    <CardSubtitle tag="h5" className="mb-2 text-muted text-center">AlgoSigner (in development)</CardSubtitle>
                    {!isAlgoSignInstalled && <CardSubtitle tag="h6" className="mb-2 text-muted text-center">(not installed)</CardSubtitle>}
                    <img src={AlgoSignerLogo} height="80" alt="AlgoSigner Logo" />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
        </>
      } */}
      {/* {transactionPage[SowCommands.ACCEPT_AND_PAY] == 3 &&
        <>
          <ModalHeader toggle={toggle}>Fund the wallet with QR</ModalHeader>
          <ModalBody>
            <Payment />

            <div style={{ textAlign: 'center' }} id="qrCode">
              <img
                key={Date.now()}
                src={qrImg}
                id="accountQR"
                style={{
                  padding: '0.5em',
                  margin: '0.5em',
                  border: '1px solid #9095AF',
                  borderRadius: '10px'
                }}
                width="250"
                height="250"
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <ActivityButton data-cy='goToTransactionPage' name="goToTransactionPage" outline color="primary" onClick={() => {
              dispatch(TransactionActions.goToTransactionPage({ transactionPage: 2, sowCommand: SowCommands.ACCEPT_AND_PAY }))
            }}>Cancel</ActivityButton>
            <ActivityButton data-cy='willCompleteTransactionAcceptAndPayQR' name="willCompleteTransactionAcceptAndPayQR" color="primary" onClick={async () => {
              // dispatch(TransactionActions.willCompleteTransactionAcceptAndPayQR({ multiSigAddress: multiSig.address, total: payment.total, sow: currentSow.sow }))
            }}>Complete the transaction</ActivityButton>
          </ModalFooter>
        </>
      } */}
      {/* {transactionPage[SowCommands.ACCEPT_AND_PAY] == 4 &&
        <>
          <ModalHeader toggle={toggle}>Fund the wallet with mnemonic secret key</ModalHeader>
          <ModalBody>
            <CardSubtitle tag="h6" className="py-1 text-muted text-center">You are signing the quote and committing to receive the service as described in the <a target="_blank" href={newAttachments.find((file: any) => file.filename === "works_agreement.pdf").downloadUrl}>works agreement</a>.</CardSubtitle>
            <CardSubtitle tag="h6" className="py-1 text-muted text-center">Furthermore, you are explicitly opt-in to receive the asset <a target="_blank" href={configuration[stage].AlgoExplorer_link["asset"] + JSON.parse(messagesCommands[SowCommands.SUBMIT].commandMessage.data).assetId}>{JSON.parse(messagesCommands[SowCommands.SUBMIT].commandMessage.data).assetId}</a>.</CardSubtitle>
            <Payment />

            {saveMnemonicMy && saveMnemonicMy.save ?
              <>
                <FormGroup>
                  <Label for="passphrase">Passphrase *</Label>
                  <Input value={passphrase} type="password" name="passphrase" id="passphrase" placeholder="passphrase"
                    onChange={(event: any) => {
                      setPassphrase(event.target.value)
                    }}
                  />
                </FormGroup>
                <Button color="link" onClick={() => {
                  delete saveMnemonicParsed[user.username]
                  localStorage.setItem('saveMnemonic', JSON.stringify(saveMnemonicParsed))
                  dispatch(TransactionActions.goToTransactionPage({ transactionPage: 2, sowCommand: SowCommands.ACCEPT_AND_PAY }))
                  dispatch(NotificationActions.willShowNotification({ message: "Passphrase deleted", type: "info" }));
                }}>Forgot passphrase? Delete passphrase and use mnemonic</Button>
              </>
              :
              <>
                <FormGroup>
                  <Label for="mnemonicSecretKey">Mnemonic Secret Key *</Label>
                  <Input data-cy="mnemonicSecretKey" value={mnemonicSecretKey} type="textarea" name="mnemonicSecretKey" id="mnemonicSecretKey" placeholder="mnemonicSecretKey"
                    onChange={(event: any) => {
                      setMnemonicSecretKey(event.target.value)
                    }}
                  />
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input name="saveMnemonicAsk" id="saveMnemonicAsk" type="checkbox"
                      onChange={(event) => setSaveMnemonicAsk(event.target.checked)}
                    />
                    Save mnemonic in local storage for quick sign
                </Label>
                </FormGroup>
              </>
            }
          </ModalBody>
          <ModalFooter>
            <ActivityButton data-cy='goToTransactionPage' name="goToTransactionPage" outline color="primary" onClick={() => {
              dispatch(TransactionActions.goToTransactionPage({ transactionPage: 2, sowCommand: SowCommands.ACCEPT_AND_PAY }))
            }}>Cancel</ActivityButton>
            <ActivityButton data-cy='willCompleteTransactionAcceptAndPay' disabled={(mnemonicSecretKey == '' && passphrase == '')} name="willCompleteTransactionAcceptAndPay" color="primary" onClick={async () => {
              saveMnemonicAsk && dispatch(ProfileActions.willToggleSaveMnemonicModal())
              dispatch(TransactionActions.willCompleteTransactionAcceptAndPayMnemonic({ mnemonicSecretKey: mnemonicSecretKey, passphrase: passphrase, saveMnemonic: saveMnemonicMy, multiSig: multiSig, params: params, currentSow: currentSow, payment: payment, arbitrator: currentChosenArbitrator, assetId: JSON.parse(messagesCommands[SowCommands.SUBMIT].commandMessage.data).assetId }))
            }}>Complete the transaction</ActivityButton>
          </ModalFooter>
        </>
      }
      {transactionPage[SowCommands.ACCEPT_AND_PAY] == 5 &&
        <>
          <ModalHeader toggle={toggle}>Fund the wallet with AlgoSigner</ModalHeader>
          <ModalBody>
            <CardSubtitle tag="h6" className="py-1 text-muted text-center">You are signing the quote and committing to receive the service as described in the <a target="_blank" href={newAttachments.find((file: any) => file.filename === "works_agreement.pdf").downloadUrl}>works agreement</a>.</CardSubtitle>
            <CardSubtitle tag="h6" className="py-1 text-muted text-center">Furthermore, you are explicitly opt-in to receive the asset <a target="_blank" href={configuration[stage].AlgoExplorer_link["asset"] + JSON.parse(messagesCommands[SowCommands.SUBMIT].commandMessage.data).assetId}>{JSON.parse(messagesCommands[SowCommands.SUBMIT].commandMessage.data).assetId}</a>.</CardSubtitle>
            <Payment />

            <ListGroupItem className='border border-primary bg-light'>
              {algoSigner.account.address + ': ' + t('transaction.payment.algo', { value: algoSigner.account.amount / 1000000 })}
            </ListGroupItem>
          </ModalBody>
          <ModalFooter>
            <ActivityButton data-cy='goToTransactionPage' name="goToTransactionPage" outline color="primary" onClick={() => {
              dispatch(TransactionActions.goToTransactionPage({ transactionPage: 2, sowCommand: SowCommands.ACCEPT_AND_PAY }))
            }}>Cancel</ActivityButton>
            <ActivityButton data-cy='willCompleteTransactionAcceptAndPayAlgoSigner' name="willCompleteTransactionAcceptAndPayAlgoSigner" color="primary"
              onClick={() => {
                dispatch(TransactionActions.willCompleteTransactionAcceptAndPayAlgoSigner({ multiSigAddress: multiSig.address, params: params, currentSow: currentSow, toPay: payment.toPay, arbitrator: currentChosenArbitrator, assetId: JSON.parse(messagesCommands[SowCommands.SUBMIT].commandMessage.data).assetId, account: algoSigner.account }))
              }}
            >Complete the transaction</ActivityButton>
          </ModalFooter>
        </>
      } */}
      {
        transactionPage[SowCommands.ACCEPT_AND_PAY] == 6 &&
        <>
          <ModalHeader toggle={toggle}>Wallet funded</ModalHeader>
          <ModalBody>
            <Jumbotron>
              <CardText>
                {t('transaction.transactionCompleted')}
              </CardText>
              {/* <CardText>
                <LinkBlockExplorer title={'Asset: ' + JSON.parse(messagesCommands[SowCommands.SUBMIT].commandMessage.data).assetId} type="asset" id={JSON.parse(messagesCommands[SowCommands.SUBMIT].commandMessage.data).assetId} />
                {transactionAcceptAndPay.tx.length == 5 &&
                  <>
                    <LinkBlockExplorer title={'Payment fee (ALGO) transaction: ' + transactionAcceptAndPay.tx[0].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[0]} />
                    <LinkBlockExplorer title={`Opt-in multisig asset ${currentSow.currency} transaction: ` + transactionAcceptAndPay.tx[1].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[1]} />
                    <LinkBlockExplorer title={'Opt-in deliverable asset transaction: ' + transactionAcceptAndPay.tx[2].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[2]} />
                    <LinkBlockExplorer title={`Payment (${currentSow.currency}) transaction: ` + transactionAcceptAndPay.tx[3].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[3]} />
                    <LinkBlockExplorer title={`Payment UC commission (${currentSow.currency}) transaction: ` + transactionAcceptAndPay.tx[4].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[4]} />
                  </>
                }
                {transactionAcceptAndPay.tx.length == 4 &&
                  <>
                    <LinkBlockExplorer title={'Payment fee (ALGO) transaction: ' + transactionAcceptAndPay.tx[0].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[0]} />
                    <LinkBlockExplorer title={'Opt-in deliverable asset transaction: ' + transactionAcceptAndPay.tx[1].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[1]} />
                    <LinkBlockExplorer title={`Payment (${currentSow.currency}) transaction: ` + transactionAcceptAndPay.tx[2].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[2]} />
                    <LinkBlockExplorer title={`Payment UC commission (${currentSow.currency}) transaction: ` + transactionAcceptAndPay.tx[3].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[3]} />
                  </>
                }
                {transactionAcceptAndPay.tx.length == 3 &&
                  <>
                    <LinkBlockExplorer title={'Opt-in transaction: ' + transactionAcceptAndPay.tx[0].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[0]} />
                    <LinkBlockExplorer title={'Payment transaction: ' + transactionAcceptAndPay.tx[1].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[1]} />
                    <LinkBlockExplorer title={`Payment UC commission (${currentSow.currency}) transaction: ` + transactionAcceptAndPay.tx[2].substring(0, 6) + '...'} type="tx" id={transactionAcceptAndPay.tx[2]} />
                  </>
                }
              </CardText> */}
            </Jumbotron>
          </ModalBody>
          <ModalFooter>
            <ActivityButton data-cy="closeAcceptAndPay" name="closeTransaction" color="primary" onClick={() => {
              dispatch(SowActions.willGetSow({ sow: currentSow.sow }))
              toggle()
            }
            }
            >Close</ActivityButton>
          </ModalFooter>
        </>
      }
      {
        transactionPage[SowCommands.ACCEPT_AND_PAY] == 7 &&
        <>
          <ModalHeader toggle={toggle}>Checkout failed</ModalHeader>
          <ModalBody>
            {/* <CardSubtitle tag="h6" className="mb-2 text-muted text-center">{multiSig.address}</CardSubtitle>
            <CardSubtitle tag="h6" className="mb-2 text-muted text-center">Balances: {multiSig.amount} ALGO</CardSubtitle> */}
            <Jumbotron>
              <CardText>
                The checkout failed, retry.
                {/* {t('transaction.transactionFailed', { errorMessage: transactionError })} */}
              </CardText>
            </Jumbotron>
          </ModalBody>
          <ModalFooter>
            <ActivityButton name="closeTransaction" color="primary" onClick={toggle}>Close</ActivityButton>
          </ModalFooter>
        </>
      }

      {/* <SaveMnemonicModal mnemonicSecretKeyProp={mnemonicSecretKey} /> */}
    </Modal >
  )
}