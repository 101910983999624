export const algorandGetAccountInfoQuery =  
`query algorandGetAccountInfo(
  $account: String
){
  algorandGetAccountInfo(
    account: $account
  ) {
    address
    assets
    createdAssets
    amount
    error
  }
}
`