export const getSowsListArbitratorQuery =  `
query getSowsListArbitrator{
  listSowsAsArbitrator {
    sows {
      sow
      arbitrators
      codeOfConduct
      currency
      deadline
      description
      numberReviews
      price
      quantity
      tags
      termsOfService
      title
      status
      buyer
      seller
      arbitrator
      createdAt
      updatedAt
      messagesToReadBuyer
      messagesToReadSeller
      messagesToReadArbitrator
      sowExpiration
      submittedDate
      signedMsig
      licenseTermsOption
      licenseTermsNotes
    }
    nextToken
  }
}
`