export const algorandSendAcceptAndPayTxQuery = 
 `mutation algorandSendAcceptAndPayTx(
  $sow: ID,
  $tx: [TransactionInput]
) {
  algorandSendAcceptAndPayTx(
    sow: $sow,
    tx: $tx
  ) {
    tx
    error
  }
}
`