import { API, graphqlOperation } from 'aws-amplify';
// import { loader } from 'graphql.macro';
import * as _ from 'lodash';

import { createSowQuery } from '../graphql/createSow'
import { draftSowQuery } from '../graphql/draftSow'
import { submitSowQuery } from '../graphql/submitSow'
import { getUploadUrlQuery } from '../graphql/getUploadUrl'
import { getDownloadUrlQuery } from '../graphql/getDownloadUrl'
import { headObjectQuery } from '../graphql/headObject'
import { deleteAttachmentQuery } from '../graphql/deleteAttachment'
import { getSowsListSellerQuery } from '../graphql/getSowsListSeller'
import { getSowsListBuyerQuery } from '../graphql/getSowsListBuyer'
import { getSowsListArbitratorQuery } from '../graphql/getSowsListArbitrator'
import { listSowAttachmentsQuery } from '../graphql/listSowAttachments'
import { getSowFromLinkQuery } from '../graphql/getSowFromLink'
import { buildHtmlQuery } from '../graphql/buildHtml'
import { buildPdfQuery } from '../graphql/buildPdf'
import { setBuyerOnceMutation } from '../graphql/setBuyerOnce'

const axios = require('axios')
const Velocity = require('velocityjs');

export const createStatementOfWork = async () => {
  // const mutation = loader('../graphql/createSow.gql')
  // console.log("createSow API mutation: ", mutation)

  try {
    console.log("createStatementOfWork apy try 1")
    const result: any = await API.graphql(graphqlOperation(createSowQuery))
    console.log("createStatementOfWork apy try 2")
    console.log("rawResult createSow: ", result)
    return result.data.createSow

  } catch (error) {
    console.log("createSow API error: ", error)
    throw error
  }
}

export const draftStatementOfWork = async (
  sow: any,
  // arbitrator: any,
  codeOfConduct: any,
  currency: any,
  buyer: any,
  deadline: any,
  description: any,
  numberReviews: any,
  price: any,
  quantity: any,
  tags: any,
  termsOfService: any,
  title: any,
  sowExpiration: any,
  licenseTermsOption: any,
  licenseTermsNotes: any
) => {

  // const mutation = loader('../graphql/draftSow.gql')

  try {
    const result: any = await API.graphql(graphqlOperation(draftSowQuery, {
      sow: sow,
      // arbitrator: arbitrator,
      codeOfConduct: codeOfConduct,
      currency: currency,
      buyer: buyer,
      deadline: deadline,
      description: description,
      numberReviews: numberReviews,
      price: price,
      quantity: quantity,
      tags: tags,
      termsOfService: termsOfService,
      title: title,
      sowExpiration: sowExpiration,
      licenseTermsOption: licenseTermsOption,
      licenseTermsNotes: licenseTermsNotes
    }))
    // console.log("draftSow result: ", result)
    return result.data.draftSow
  } catch (error) {
    console.log("draftSow API error: ", error)
    throw error
  }
}

export const submitStatementOfWork = async (
  sow: any,
  // arbitrators: any,
  codeOfConduct: any,
  currency: any,
  buyer: any,
  deadline: any,
  description: any,
  numberReviews: any,
  price: any,
  quantity: any,
  tags: any,
  termsOfService: any,
  title: any,
  sowExpiration: any,
  licenseTermsOption: any,
  licenseTermsNotes: any
) => {

  // const mutation = loader('../graphql/submitSow.gql')

  try {
    const result: any = await API.graphql(graphqlOperation(submitSowQuery, {
      sow: sow,
      // arbitrators: arbitrators,
      codeOfConduct: codeOfConduct,
      currency: currency,
      buyer: buyer,
      deadline: deadline,
      description: description,
      numberReviews: numberReviews,
      price: price,
      quantity: quantity,
      tags: tags,
      termsOfService: termsOfService,
      title: title,
      sowExpiration: sowExpiration,
      licenseTermsOption: licenseTermsOption,
      licenseTermsNotes: licenseTermsNotes
    }))
    // console.log("submitSow rawResult: ", result)
    return result.data.submitSow
  } catch (error) {
    console.log("submitSow API error: ", error)
    throw error
  }
}

export const getUploadUrl = async (sow: any, attachmentName: any, expires: any, fileType: any) => {
  // const query = loader('../graphql/getUploadUrl.gql');

  try {
    const result: any = await API.graphql(graphqlOperation(getUploadUrlQuery, { sow: sow, key: attachmentName, expires: expires, type: fileType }));
    // console.log('getUploadUrl with result: ', result);
    return result.data.getUploadUrl
  } catch (error) {
    console.log("getUploadUrl API error: ", error)
  }
}

export const getDownloadUrl = async (sow: any, attachmentName: any, expires: any, versionId: any) => {
  // const query = loader('../graphql/getDownloadUrl.gql');

  try {
    const result: any = await API.graphql(graphqlOperation(getDownloadUrlQuery, { sow: sow, key: attachmentName, expires: expires, versionId: versionId }));
    // console.log('getDownloadUrl with result: ', result);
    return result.data.getDownloadUrl
  } catch (error) {
    console.log("getDownloadUrl API error: ", error)
  }
}

export const uploadFileToS3 = async (url: any, file: any) => {
  try {
    const axiosResponse = await axios.put(url, file, {
      headers: { 'Content-Type': file.type, 'x-amz-acl': 'private' }
    });
    // console.log("uploadFileToS3 axiosResponse: ", axiosResponse)
  } catch (error) {
    console.log(error)
  }
}

export const headObject = async (sow: any, key: any) => {
  // const query = loader('../graphql/headObject.gql')

  try {
    const result: any = await API.graphql(graphqlOperation(headObjectQuery, { sow: sow, key: key }))
    console.log("in headObject result: ", result)
    return result.data.headObject
  } catch (error) {
    console.log("headObject API error: ", error)
    throw error
  }
}

export const deleteAttachment = async (fileName: any, sow: any) => {
  // const mutation = loader('../graphql/deleteAttachment.gql')

  try {
    const result: any = await API.graphql(graphqlOperation(deleteAttachmentQuery, { key: fileName, sow: sow }))
    // console.log("in deleteAttachment result: ", result)
    return result.data.deleteAttachment
  } catch (error) {
    console.log("deleteAttachment API error: ", error)
    throw error
  }
}

export const getSowsListSeller = async () => {
  // const query = loader('../graphql/getSowsListSeller.gql');

  try {
    const rawResult: any = await API.graphql({ query: getSowsListSellerQuery });
    // console.log('getSowsListSeller with rawResult: ', rawResult);
    return rawResult.data.listSowsAsSeller

  } catch (error: any) {
    // throw error
    return error.data.listSowsAsSeller
  }
}

export const getSowsListBuyer = async () => {
  // const query = loader('../graphql/getSowsListBuyer.gql');

  try {
    const rawResult: any = await API.graphql({ query: getSowsListBuyerQuery });
    // console.log('getSowsListBuyer with rawResult: ', rawResult);
    return rawResult.data.listSowsAsBuyer

  } catch (error: any) {
    // throw error
    return error.data.listSowsAsBuyer
  }
}

export const getSowsListArbitrator = async () => {
  // const query = loader('../graphql/getSowsListArbitrator.gql');

  try {
    const rawResult: any = await API.graphql({ query: getSowsListArbitratorQuery });
    // console.log('getSowsListArbitrator with rawResult: ', rawResult);
    return rawResult.data.listSowsAsArbitrator

  } catch (error: any) {
    // throw error
    return error.data.listSowsAsArbitrator
  }
}

export const getSowAttachmentsList = async (sow: any) => {
  // const query = loader('../graphql/listSowAttachments.gql');

  try {
    const result: any = await API.graphql(graphqlOperation(listSowAttachmentsQuery, { sow: sow }));
    console.log('listSowAttachments with result: ', result);
    return result.data.listSowAttachments.attachments
  } catch (error) {
    throw error
  }
}


export const getSow = async (sow: any) => {
  // const query = loader('../graphql/getSowFromLink.gql');

  try {
    const result: any = await API.graphql(graphqlOperation(getSowFromLinkQuery, { sow: sow }));
    console.log('getSow with result: ', result);
    return result.data.getSowFromLink
  } catch (error) {
    throw error
  }
}

export const getSowHtml = async (templt, data) => {
  try {
    const axiosResponse = await axios.get(templt);
    // console.log("getSowHtml axiosResponse: ", axiosResponse)

    const html_doc = Velocity.render(axiosResponse.data, {
      context: {
        SELLER_NAME: data.seller_name,
        SELLER_ADDRESS: data.seller_address,
        BUYER_NAME: data.buyer_name,
        BUYER_ADDRESS: data.buyer_address,
        TITLE: data.title,
        STARTDATE: data.startdate,
        PRICE: data.price,
        CURRENCY: data.currency,
        MSIG_ADDRESS: data.msig_address,
        UC_FEE: data.uc_fee,
        DEADLINE: data.deadline,
        N_REVIEWS: data.n_reviews,
        ACCEPTANCE_TIME: data.acceptance_time,
        ARBITRATOR_NAME: data.arbitrator_name,
        ARBITRATOR_ADDRESS: data.arbitrator_address,
        ARBITRATOR_NAMES: data.arbitrator_names,
        PERCENTAGE_ARBITRATOR_FEE: data.percentage_arbitrator_fee,
        FLAT_ARBITRATOR_FEE: data.flat_arbitrator_fee,
        DESCRIPTION: data.description,
        DEFINITION_OF_DONE: data.definition_of_done,
        LICENSE: data.license,
        EMPTY: data.empty,
        LICENSE_TERMS_OPTION: data.licenseTermsOption,
        LICENSE_TERMS_NOTES: data.licenseTermsNotes
      },
      util: {
        stringify: (obj) => JSON.stringify(obj)
      }
    });
    // console.log(html_doc);
    return html_doc;
  } catch (error) {
    throw error
  }
}

export const buildHtmlBackend = async (sow: any) => {
  // const mutation = loader('../graphql/buildHtml.gql');

  try {
    const result: any = await API.graphql(graphqlOperation(buildHtmlQuery, { sow: sow }));
    console.log('buildHtml with result: ', result);
    return result.data.buildHtml
  } catch (error) {
    throw error
  }
}

export const buildPdf = async (sow: any) => {
  // const mutation = loader('../graphql/buildPdf.gql');

  try {
    const result: any = await API.graphql(graphqlOperation(buildPdfQuery, { sow: sow }));
    console.log('buildPdf with result: ', result);
    return result.data.buildPdf
  } catch (error) {
    throw error
  }
}

export const setBuyerOnce = async (sow: any, bankInfo: any) => {
  // const mutation = loader('../graphql/buildPdf.gql');

  try {
    const result: any = await API.graphql(graphqlOperation(setBuyerOnceMutation, { sow: sow, bankInfo: bankInfo }));
    console.log('buildPdf with result: ', result);
    return result
  } catch (error) {
    throw error
  }
}