export const listSowAttachmentsQuery =  `
query listSowAttachments(
  $sow: ID
){
  listSowAttachments(
    sow: $sow
  ){
    attachments {
      key
      size
      type
      etag
      versionsData
    }
    nextToken
  }
}
 `