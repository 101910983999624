export const headObjectQuery =  `
query headObject (
  $sow: ID,
  $key: String
) {
  headObject(
    sow: $sow
    key: $key
  ) {
    ETag   
    VersionId  
  }
}
 `