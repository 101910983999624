export const algorandDispenseFakeAssetQuery =  
`mutation algorandDispenseFakeAsset(
  $addr: String
  $asset: FakeAsset
) {
  algorandDispenseFakeAsset(
    addr: $addr
    asset: $asset
  )
}
`