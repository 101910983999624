export const algorandPollAccountAmountQuery =  
`query algorandPollAccountAmount(
  $id: ID!,
  $account: String,
  $amount: Int
){
  algorandPollAccountAmount(
    id: $id
    account: $account
    amount: $amount
  ) {
    id
    status
    error
  }
}
`