export const algorandSendClaimMilestoneMetQuery =  
`mutation algorandSendClaimMilestoneMet(
  $sow: ID,
  $tx: [TransactionInput]
  $backupTx: [TransactionInput]
) {
  algorandSendClaimMilestoneMet(
    sow: $sow,
    tx: $tx,
    backupTx: $backupTx
  )
}
`