import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container, Button, Navbar, NavbarBrand, NavbarToggler, Collapse,
  Nav, NavItem, NavLink, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col
} from 'reactstrap'
import { Link, useHistory, Redirect } from "react-router-dom";

import { configuration } from './config'
import * as AuthApi from './api/auth'
import { selectors as AuthSelectors } from './store/slices/auth'
import { actions as AuthActions } from './store/slices/auth'
import { selectors as ProfileSelectors, actions as ProfileActions } from './store/slices/profile'
import { actions as AssetCurrencyActions } from './store/slices/assetCurrency'
import { actions as TransactionActions } from './store/slices/transaction'
import { LinkBlockExplorer } from './components/common/LinkBlockExplorer'
import uniscrowLogo from './images/uniscrowLogo.png'
import Portrait from './images/Portrait.png'

const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

export const Header = ({ className }: any) => {

  let history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const user = useSelector(AuthSelectors.getUser)
  const dispatch = useDispatch()
  const addDefaultSrc = (ev: any) => { ev.target.src = Portrait }

  return (
    <Navbar className={className} color="secondary" light expand="md" style={{ height: '130px' }}>
      <Row className="d-flex align-items-center">
        <Col className="col-9 col-md-4">
          <NavbarBrand href="/" className="">
            <img src={uniscrowLogo} alt="Uniscrow Logo" className="img-fluid" />
          </NavbarBrand>
        </Col>
        <Col className="col-3 col-md-8 text-right">
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>

            <Nav className="mr-auto" navbar />
            <Nav>

              {!isAuthenticated ?
                <NavItem>
                  <NavLink href="/login">Login</NavLink>
                </NavItem>
                :
                <>

                  <NavItem>
                    <NavLink href="/sales" className="d-none d-lg-block">Sales</NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="/purchases" className="d-none d-lg-block">Purchases</NavLink>
                  </NavItem>

                  {/* web */}
                  <UncontrolledDropdown nav inNavbar className="d-none d-lg-block">
                    <DropdownToggle data-cy='headerProfileToggler' nav>
                      <Row>
                        <Col className="text-center">
                          {userAttributes.given_name} {userAttributes.family_name}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center">
                          <img height="70" alt="Portrait" onError={addDefaultSrc}
                            src={`${configuration[stage].host}/resources/${user.username}/portrait?${Date.now()}`}
                          />
                        </Col>
                      </Row>
                    </DropdownToggle>

                    <DropdownMenu right>
                      <DropdownItem header >
                        <Row>
                          <Col className="text-center">
                            {userAttributes.given_name} {userAttributes.family_name}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center">
                            <img height="70" alt="Portrait" onError={addDefaultSrc}
                              src={`${configuration[stage].host}/resources/${user.username}/portrait?${Date.now()}`}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center">
                            {userAttributes.email}
                          </Col>
                        </Row>
                      </DropdownItem>
                      {/* <DropdownItem header>ALGO: {userAttributes.public_key}</DropdownItem> */}
                      {/* <DropdownItem header>
                      {userAttributes.public_key && <LinkBlockExplorer title={'ALGO: ' + userAttributes.public_key.substring(0, 6) + '...'} type="address" id={userAttributes.public_key} />}
                      </DropdownItem> */}
                      {/* <DropdownItem header>KUDOS: 12</DropdownItem> */}
                      <DropdownItem divider />
                      <DropdownItem data-cy='profile' onClick={() => {
                        dispatch(ProfileActions.willGoToProfile({ user: user.username, history: history }));
                      }}>Profile</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem tag="a" href={configuration[stage].Trustshare_link} target="_blank"
                      >Trustshare</DropdownItem>
                      {userAttributes.uniscrow_email &&
                        <>
                          <DropdownItem divider />
                          <DropdownItem tag={Link} to="/mail-integration"
                          >Mail integration</DropdownItem>
                        </>
                      }
                      {/* <DropdownItem onClick={() => {
                      dispatch(AssetCurrencyActions.willGoToAssetCurrencyPage({ address: userAttributes.public_key, history: history }));
                      }}>Opt-in asset currency</DropdownItem> */}
                      <DropdownItem divider />
                      <DropdownItem data-cy='logout' onClick={() => {
                        dispatch(AuthActions.willLogoutUser());
                      }}>Logout</DropdownItem>

                    </DropdownMenu>
                  </UncontrolledDropdown>

                  {/* mobile */}
                  <div className="d-md-none border border-primary bg-secondary ml-n15" style={{ width: "1000%" }}>
                    <NavItem header className="ml-auto">
                      <Row>
                        <Col className="text-center">
                          {userAttributes.given_name} {userAttributes.family_name}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center">
                          <img height="70" alt="Portrait" onError={addDefaultSrc}
                            src={`${configuration[stage].host}/resources/${user.username}/portrait?${Date.now()}`}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center">
                          {userAttributes.email}
                        </Col>
                      </Row>
                    </NavItem>
                    {/* <NavItem header>ALGO: {userAttributes.public_key}</NavItem> */}
                    {/* <NavItem header>
                    {userAttributes.public_key && <LinkBlockExplorer title={'ALGO: ' + userAttributes.public_key.substring(0, 6) + '...'} type="address" id={userAttributes.public_key} />}
                    </NavItem> */}
                    {/* <NavItem header>KUDOS: 12</NavItem> */}
                    <NavLink data-cy='profile' onClick={() => {
                      dispatch(ProfileActions.willGoToProfile({ user: user.username, history: history }));
                    }}>Profile</NavLink>
                    <NavLink tag="a" href={configuration[stage].Trustshare_link} target="_blank"
                    >Trustshare</NavLink>
                    {userAttributes.uniscrow_email &&
                      <NavLink tag={Link} to="/mail-integration"
                      >Mail integration</NavLink>
                    }
                    {/* <NavItem onClick={() => {
                    dispatch(AssetCurrencyActions.willGoToAssetCurrencyPage({ address: userAttributes.public_key, history: history }));
                    }}>Opt-in asset currency</NavItem> */}
                    <NavLink data-cy='logout' onClick={() => {
                      dispatch(AuthActions.willLogoutUser());
                    }}>Logout</NavLink>
                  </div>
                </>
              }
            </Nav>

          </Collapse>
        </Col>
      </Row>
    </Navbar>
  )
}