export const getResourceUrlQuery =  `
query getResourceUrl (
  $key: String,
  $expires: Int,
  $type: String
) {
  getResourceUrl(
    key: $key
    expires: $expires
    type: $type
  )
}
`