import * as Yup from 'yup';

import { configuration } from '../config'
const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

export const StatementOfWorkSchema = Yup.object().shape({
  sow: Yup.string()
    .required('Required'),
  // buyer: Yup.string().when('status', {
  //   is: 'DRAFT',
  //   then: Yup.string()
  //     .test('Should not contain your email address', 'Should not contain your email address', (value, context) => value != context.parent.seller)
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!')
  //     .required('Required')
  //     .test('Should be an email address', 'Should be an email address', (value, context) => value === "not_set" ? false : true)
  // }),
  title: Yup.string()
    .test(`'Draft Title' is not accepted as a title!`, `'Draft Title' is not accepted as a title!`, (value) => value != 'Draft Title')
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  description: Yup.string()
    .max(10000, 'Too Many!')
    .required('Required'),
  quantity: Yup.number()
    .min(0, 'Too Few!')
    .max(50, 'Too Many!'),
  price: Yup.number()
    .test(
      "maxDigitsAfterDecimal",
      "Price must be a positive number with max. 2 digits after decimal or less",
      (value) => /^\d+(\.\d{1,2})?$/.test(value?.toString() || "")
    )
    .min(2.5, 'Too Few!')
    .max(configuration[stage].sow_price_limit, 'For such amount please contact team@uniscrow.com.')
    .required('Required'),
  currency: Yup.string()
    .min(3, 'Too Short!')
    .required('Required'),
  deadline: Yup.date()
    .min(new Date().toISOString())
    .required('Required'),
  // tags: Yup.array()
  //   .min(1, 'At least one tag required!')
  //   .required('Required'),
  numberReviews: Yup.number()
    .min(1, 'Too Few!')
    .max(50, 'Too Many!')
    .required('Required'),
  // termsOfService: Yup.boolean()
  //   .oneOf([true], "The Terms of Service must be accepted.")
  //   .required('Required'),
  // codeOfConduct: Yup.boolean()
  //   .oneOf([true], "The Code of Conduct must be accepted.")
  //   .required('Required'),
  // arbitrator: Yup.object({
  //   user: Yup.string()
  // })
  //   .test('Required', 'Required', (value) => value.hasOwnProperty('user'))
  //   .required('Required'),
  // sowExpiration: Yup.number()
  //   .min(1, 'Select expiration')
  //   .required('Required'),
  licenseTermsOption: Yup.string()
    .required('Required'),
  licenseTermsNotes: Yup.string()
    .min(3, 'Too Short!')
    .required('Required'),
});