export const algorandGetTxQuery =  
`query algorandGetTx(
  $sow: ID!
){
  algorandGetTx(
    sow: $sow
  ) {
    hash_round 
    round_sow
    tx {
      txID
      blob
    } 
    backupTx {
      txID
      blob
    }
    sow
    status
  }
}
`