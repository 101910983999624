export const algorandPutTransactionQuery =  
`mutation algorandPutTransaction(
  $sow: String,
  $blob: String
) {
  algorandPutTransaction(
    sow: $sow,
    blob: $blob
  )
}
`