export const getProfileDataGraphql =  `
query getProfileData($user: ID){
  given_name: getProfileData( 
    user: $user
    name: "given_name"
  ){
    value
  }

  family_name: getProfileData( 
    user: $user
    name: "family_name"
  ){
    value
  }

  email: getProfileData( 
    user: $user
    name: "email"
  ){
    value
  }

  public_key: getProfileData( 
    user: $user
    name: "public_key"
  ){
    value
  }

  bio: getProfileData( 
    user: $user
    name: "bio"
  ){
    value
  }

  address: getProfileData( 
    user: $user
    name: "address"
  ){
    value
  }

  uniscrow_email: getProfileData( 
    user: $user
    name: "uniscrow_email"
  ){
    value
  }
}
`