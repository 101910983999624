export const configuration: any = {
  dev: {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_uGNmM1CcQ',
      userPoolWebClientId: '1otlc27kg91plui4st9ludnbfq',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false
    },
    'aws_appsync_graphqlEndpoint': 'https://7dh62yyrlrfadc3m77bpp4kk4y.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
    // 'arbitrators_list_webhook': 'https://dbyc3f5xvj.execute-api.eu-west-1.amazonaws.com/dev/arbitrators',
    'host': 'https://dev.uniscrow.com',
    // 'algorand_net': 'TestNet',
    // 'algorand_poll_account_amount_time': 120000,
    // 'legal_document_template_key': 'vtl_templates/legal_sow_doc.vtl',
    'works_agreement_key': 'works_agreement',
    'specs_document_key': 'specs_document',
    'deliverable_key': 'deliverable',
    'currency_default': "GBP",
    // 'uc_backup_public_key': '3IB3QQKFQACYNQDD2G2CVV7I7ZZDY5ZLFW7SOJQVQG6I4CPCOVE2UU35OA',
    // 'submitAsset_unitName': 'UC-',
    // 'submitAsset_assetName': 'UC project ',
    // 'deliverableAsset_unitName': 'UCDLV-',
    // 'deliverableAsset_assetName': 'UCDLV project ',
    // 'AlgoExplorer_link': {
    //   'tx': 'https://testnet.algoexplorer.io/tx/',
    //   'address': 'https://testnet.algoexplorer.io/address/',
    //   'asset': 'https://testnet.algoexplorer.io/asset/'
    // },
    // 'uc_commission_percentage': 1,
    // 'uc_commission_public_key': '7NCU4YXPFQWNWBDVDY4EZI7H5LZUXKTPA7MEB3EM7U37TQF6JR57CTGIP4'
    'Trustshare_domain': 'uncommondigital.sandbox',
    'Trustshare_link_escrow': 'https://uncommondigital.sandbox.trustshare.co/escrow/',
    'Trustshare_link': 'https://uncommondigital.sandbox.trustshare.co',
    'sow_price_limit': 5000
  },
  // demo: {
  //   Auth: {
  //     // REQUIRED - Amazon Cognito Region
  //     region: 'eu-west-1',
  //     // OPTIONAL - Amazon Cognito User Pool ID
  //     userPoolId: 'eu-west-1_BUjZx7mY6',
  //     userPoolWebClientId: '3g1f1q1g79aimflohv2ub8jf2q',
  //     // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  //     mandatorySignIn: false
  //   },
  //   'aws_appsync_graphqlEndpoint': 'https://ffcdzwrsorfytmiktc3id4jw74.appsync-api.eu-west-1.amazonaws.com/graphql',
  //   'aws_appsync_region': 'eu-west-1',
  //   'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  //   'arbitrators_list_webhook': 'https://g4uq7oz57f.execute-api.eu-west-1.amazonaws.com/demo/arbitrators',
  //   'host': 'https://demo.uncommon-demo.com',
  //   'algorand_net': 'TestNet',
  //   'algorand_poll_account_amount_time': 120000,
  //   'legal_document_template_key': 'vtl_templates/legal_sow_doc.vtl',
  //   'works_agreement_key': 'works_agreement.pdf',
  //   'specs_document_key': 'specs_document',
  //   'deliverable_key': 'deliverable',
  //   'uc_backup_public_key': '3IB3QQKFQACYNQDD2G2CVV7I7ZZDY5ZLFW7SOJQVQG6I4CPCOVE2UU35OA',
  //   'submitAsset_unitName': 'UC-',
  //   'submitAsset_assetName': 'UC project ',
  //   'deliverableAsset_unitName': 'UCDLV-',
  //   'deliverableAsset_assetName': 'UCDLV project ',
  //   'AlgoExplorer_link': {
  //     'tx': 'https://testnet.algoexplorer.io/tx/',
  //     'address': 'https://testnet.algoexplorer.io/address/',
  //     'asset': 'https://testnet.algoexplorer.io/asset/'
  //   },
  //   'uc_commission_percentage': 1,
  //   'uc_commission_public_key': '7NCU4YXPFQWNWBDVDY4EZI7H5LZUXKTPA7MEB3EM7U37TQF6JR57CTGIP4'
  // },
  prod: {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_VOe5OzBZc',
      userPoolWebClientId: '1nfonfomsho9f1vh4r8j940f70',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false
    },
    'aws_appsync_graphqlEndpoint': 'https://sqcvaaenhjak7px7t743ytjmvm.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
    // 'arbitrators_list_webhook': 'https://dbyc3f5xvj.execute-api.eu-west-1.amazonaws.com/dev/arbitrators',
    'host': 'https://app.uniscrow.com',
    // 'algorand_net': 'TestNet',
    // 'algorand_poll_account_amount_time': 120000,
    // 'legal_document_template_key': 'vtl_templates/legal_sow_doc.vtl',
    'works_agreement_key': 'works_agreement',
    'specs_document_key': 'specs_document',
    'deliverable_key': 'deliverable',
    'currency_default': "EUR",
    // 'uc_backup_public_key': '3IB3QQKFQACYNQDD2G2CVV7I7ZZDY5ZLFW7SOJQVQG6I4CPCOVE2UU35OA',
    // 'submitAsset_unitName': 'UC-',
    // 'submitAsset_assetName': 'UC project ',
    // 'deliverableAsset_unitName': 'UCDLV-',
    // 'deliverableAsset_assetName': 'UCDLV project ',
    // 'AlgoExplorer_link': {
    //   'tx': 'https://testnet.algoexplorer.io/tx/',
    //   'address': 'https://testnet.algoexplorer.io/address/',
    //   'asset': 'https://testnet.algoexplorer.io/asset/'
    // },
    // 'uc_commission_percentage': 1,
    // 'uc_commission_public_key': '7NCU4YXPFQWNWBDVDY4EZI7H5LZUXKTPA7MEB3EM7U37TQF6JR57CTGIP4'
    'Trustshare_domain': 'uniscrow',
    'Trustshare_link_escrow': 'https://uniscrow.trustshare.co/escrow/',
    'Trustshare_link': 'https://uniscrow.trustshare.co',
    'sow_price_limit': 5000
  }
}