import {
  Row, Col,
  Container, Button, Navbar, NavbarBrand, NavbarToggler, Collapse,
  Nav, NavItem, NavLink, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  CardText
} from 'reactstrap'
import { Link } from "react-router-dom";

import AlgorandLogo from './images/Algorand.png'

export const Footer = ({ className }: any) => {

  return (
    <Container className={className} style={{ height: '0px' }}>
      <Navbar light expand="md">
        <Nav navbar className="mx-auto">
          <Row className="text-center d-flex justify-content-md-center">
            <Col className="col-6 col-md-auto">
              <NavItem className="mx-3 text-muted" tag={Link} to="/">
                Home
              </NavItem>
            </Col>
            <Col className="col-6 col-md-auto">
              <NavItem className="mx-3 text-muted" tag={Link} to="/contact-us">
                Contact Us
              </NavItem>
            </Col>
            {/* <Col className="col-6 col-md-auto">
              <NavItem className="mx-3 text-muted" tag={Link} to="/cookie-policy">
                Cookie Policy
              </NavItem>
            </Col> */}
            <Col className="col-6 col-md-auto">
              <NavItem className="mx-3 text-muted" tag={Link} to="/privacy-policy">
                Privacy Policy
              </NavItem>
            </Col>
            <Col className="col-6 col-md-auto">
              <NavItem className="mx-3 text-muted" tag={Link} to="/terms-of-service">
                Terms of Service
              </NavItem>
            </Col>
          </Row>
        </Nav>
      </Navbar>
      <Row className="text-muted text-center">
        <Col>
          <CardText style={{ fontSize: 14 }}>Uncommon Digital SRL © All rights reserved.{/*  Powered by <img src={AlgorandLogo} height="14" alt="Algorand Logo" /> */}</ CardText>
        </Col>
      </Row>
    </Container>
  )
}