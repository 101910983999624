export const algorandSendTokenCreationTxQuery =  
`mutation algorandSendTokenCreationTx(
  $sow: ID,
  $tx: [TransactionInput]
) {
  algorandSendTokenCreationTx(
    sow: $sow,
    tx: $tx
  ) {
    assetId
    tx
    error
  }
}
`