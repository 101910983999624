import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row, Col, Card, Label,
  CardTitle, CardSubtitle, CardText
} from 'reactstrap';
import { Formik } from 'formik';
// import 'react-chat-elements/dist/main.css';
import { MessageBox, MessageList, Input as InputChatElements, Button, Avatar } from 'react-chat-elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { useTranslation, Trans } from 'react-i18next';

import { configuration } from '../config'
import { actions as TransactionActions, selectors as TransactionSelectors } from '../store/slices/transaction'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { actions as ChatActions, selectors as ChatSelectors } from '../store/slices/chat'
import { selectors as AuthSelectors } from '../store/slices/auth'
import { actions as NotificationActions } from '../store/slices/notification'
import { actions as SowActions, selectors as SowSelectors, SowStatus, SowCommands } from '../store/slices/sow'
import { actions as ArbitratorActions, selectors as ArbitratorSelectors } from '../store/slices/arbitrator'
import { actions as AssetCurrencyActions, selectors as AssetCurrencySelectors } from '../store/slices/assetCurrency'
import { ActivityButton } from './common/ActivityButton'
import { SowAttachmentsInput } from './SowAttachmentsInput'
// import { LinkBlockExplorer } from './common/LinkBlockExplorer'
import { AcceptAndPay } from '../components/transaction/AcceptAndPay'
import { ClaimMilestoneMet } from '../components/transaction/ClaimMilestoneMet'
import { AcceptMilestone } from '../components/transaction/AcceptMilestone'
import { Reject } from '../components/transaction/Reject'
import { RequestReview } from '../components/transaction/RequestReview'
import Portrait from '../images/Portrait.png'
import uniscrowIcon from '../images/uniscrowIcon.png'

const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

const addDefaultSrc = (ev: any) => { ev.target.src = Portrait }

function updateScroll() {
  var element: any = document.getElementById("chatMessages");
  element.scrollTop = element.scrollHeight;
}

export const ChatSow = ({ currentSow }: any) => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const message = useSelector(ChatSelectors.getMessage)
  const user = useSelector(AuthSelectors.getUser)
  const users = useSelector(ProfileSelectors.getUsers)
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const newAttachments = useSelector(SowSelectors.getNewAttachments);
  const algorandAccount = useSelector(ProfileSelectors.getAlgorandAccount)
  const assetsCurrencies = useSelector(AssetCurrencySelectors.getAssetsCurrencies)
  const messages = useSelector(ChatSelectors.getMessages)
  const transactionModal = useSelector(TransactionSelectors.getTransactionModal)
  let inputRef: any = React.createRef();

  const toggleModalAcceptSow = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.ACCEPT_AND_PAY }));
  const toggleModalClaimMilestoneMet = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.CLAIM_MILESTONE_MET }));
  const toggleModalAcceptMilestone = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.ACCEPT_MILESTONE }));
  const toggleModalReject = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.REJECT }));
  const toggleModalRequestReview = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.REQUEST_REVIEW }));

  React.useEffect(() => {
    const refreshChat = setInterval(() => {
      dispatch(ChatActions.willRefreshSowChat({ messages: messages, sow: currentSow.sow }))
      dispatch(SowActions.willGetSow({ sow: currentSow.sow }))
    }, 30000);

    return () => clearInterval(refreshChat)
  }, []);

  React.useEffect(() => {
    updateScroll()
  }, [messages]);

  return (
    <>
      <Row id='chatMessages' style={{ overflow: 'auto', minHeight: '100px', maxHeight: '500px' }}>
        <Col>
          <Card>
            {
              messages.map((msg: any, index: any) => {
                return (
                  <>
                    <Row>
                      <Col className="col-auto pl-4 d-none d-lg-block">
                        {user.username != msg.from &&
                          <Avatar
                            src={msg.from === "SYSADMIN" ? uniscrowIcon : `${configuration[stage].host}/resources/${msg.from}/portrait?${Date.now()}`}
                            alt="Portrait"
                            size="small"
                            type="circle flexible"
                            onError={addDefaultSrc}
                          />
                        }
                      </Col>
                      <Col className={user.username == msg.from ? "ml-auto col-md-8 col-12" : "col-md-8 col-12"}>
                        <MessageBox
                          key={index}
                          data-cy='messageChat'
                          className={user.username == msg.from ? 'chatMessageBoxRight' : 'chatMessageBoxLeft'}
                          title={msg.from == "SYSADMIN" ? msg.from : users[msg.from].given_name + ' ' + users[msg.from].family_name}
                          titleColor='black'
                          position={user.username == msg.from ? 'right' : 'left'}
                          type={((msg.type == 'TEXT' || msg.type == 'COMMAND') || (msg.type == 'ATTACHMENT' && msg.attachmentMessage.type.startsWith('image'))) ?
                            'text' : 'file'}
                          text={
                            <>
                              {msg.textMessage ?
                                <CardText>
                                  {msg.textMessage.message}
                                </CardText>
                                : msg.commandMessage ?
                                  <>
                                    <CardTitle data-cy="chatCommand" className={msg.commandMessage.command == SowCommands.SYSTEM_SIGN ? "text-center text-primary font-weight-bold" : "text-primary font-weight-bold"}>
                                      {t(`chat.SowCommands.${msg.commandMessage.command}`)}
                                    </CardTitle>
                                    {/* {msg.commandMessage.data && JSON.parse(msg.commandMessage.data).tx &&
                                <CardSubtitle className={msg.commandMessage.command == SowCommands.SYSTEM_SIGN ? "text-center" : ""}>
                                  {msg.commandMessage.command == SowCommands.ACCEPT_AND_PAY ?
                                    <>
                                      {JSON.parse(msg.commandMessage.data).tx.length == 5 &&
                                        <>
                                          <LinkBlockExplorer title={'Payment fee (ALGO) transaction: ' + JSON.parse(msg.commandMessage.data).tx[0].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[0]} />
                                          <LinkBlockExplorer title={`Opt-in multisig asset ${currentSow.currency} transaction: ` + JSON.parse(msg.commandMessage.data).tx[1].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[1]} />
                                          <LinkBlockExplorer title={'Opt-in deliverable asset transaction: ' + JSON.parse(msg.commandMessage.data).tx[2].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[2]} />
                                          <LinkBlockExplorer title={`Payment (${currentSow.currency}) transaction: ` + JSON.parse(msg.commandMessage.data).tx[3].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[3]} />
                                          <LinkBlockExplorer title={`Payment UC commission (${currentSow.currency}) transaction: ` + JSON.parse(msg.commandMessage.data).tx[4].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[4]} />
                                        </>
                                      }
                                      {JSON.parse(msg.commandMessage.data).tx.length == 4 &&
                                        <>
                                          <LinkBlockExplorer title={'Payment fee (ALGO) transaction: ' + JSON.parse(msg.commandMessage.data).tx[0].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[0]} />
                                          <LinkBlockExplorer title={'Opt-in deliverable asset transaction: ' + JSON.parse(msg.commandMessage.data).tx[1].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[1]} />
                                          <LinkBlockExplorer title={`Payment (${currentSow.currency}) transaction: ` + JSON.parse(msg.commandMessage.data).tx[2].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[2]} />
                                          <LinkBlockExplorer title={`Payment UC commission (${currentSow.currency}) transaction: ` + JSON.parse(msg.commandMessage.data).tx[3].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[3]} />
                                        </>
                                      }
                                      {JSON.parse(msg.commandMessage.data).tx.length == 3 &&
                                        <>
                                          <LinkBlockExplorer title={'Opt-in transaction: ' + JSON.parse(msg.commandMessage.data).tx[0].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[0]} />
                                          <LinkBlockExplorer title={'Payment transaction: ' + JSON.parse(msg.commandMessage.data).tx[1].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[1]} />
                                          <LinkBlockExplorer title={'Payment UC commission transaction: ' + JSON.parse(msg.commandMessage.data).tx[2].substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx[2]} />
                                        </>
                                      }
                                    </>
                                    :
                                    msg.commandMessage.command == SowCommands.ACCEPT_MILESTONE ?
                                      <>
                                        <LinkBlockExplorer title={`Multisig transaction: ${JSON.parse(msg.commandMessage.data).tx[0].substring(0, 6)}...`} type="tx" id={JSON.parse(msg.commandMessage.data).tx[0]} />
                                        <LinkBlockExplorer title={`Opt-in transaction: ${JSON.parse(msg.commandMessage.data).tx[1].substring(0, 6)}...`} type="tx" id={JSON.parse(msg.commandMessage.data).tx[1]} />
                                        <LinkBlockExplorer title={`Asset transfer transaction: ${JSON.parse(msg.commandMessage.data).tx[2].substring(0, 6)}...`} type="tx" id={JSON.parse(msg.commandMessage.data).tx[2]} />
                                      </>
                                      : <LinkBlockExplorer title={'Transaction: ' + JSON.parse(msg.commandMessage.data).tx.substring(0, 6) + '...'} type="tx" id={JSON.parse(msg.commandMessage.data).tx} />
                                  }
                                </CardSubtitle>
                              }
                              {msg.commandMessage.data && JSON.parse(msg.commandMessage.data).assetId &&
                                <CardSubtitle className={msg.commandMessage.command == SowCommands.SYSTEM_SIGN ? "text-center mt-1" : "mt-1"}>
                                  <LinkBlockExplorer title={'Notarization proof: ' + JSON.parse(msg.commandMessage.data).assetId} type="asset" id={JSON.parse(msg.commandMessage.data).assetId} />
                                </CardSubtitle>
                              } */}
                                    {msg.commandMessage.data && JSON.parse(msg.commandMessage.data).reviews_left &&
                                      <CardSubtitle className="text-center text-muted text-break">
                                        {'#' + JSON.parse(msg.commandMessage.data).reviews_left + '\n' + JSON.parse(msg.commandMessage.data).message}
                                      </CardSubtitle>
                                    }
                                    {(msg.commandMessage.command == SowCommands.UPDATE_ESCROW_TRANSACTION ||
                                      msg.commandMessage.command == SowCommands.ACCEPT_AND_PAY ||
                                      msg.commandMessage.command == SowCommands.ACCEPT_MILESTONE ||
                                      msg.commandMessage.command == SowCommands.RAISE_ESCROW_DISPUTE ||
                                      msg.commandMessage.command == SowCommands.RESOLVE_ESCROW_DISPUTE ||
                                      msg.commandMessage.command == SowCommands.PAUSE_ESCROW_PAYMENT)
                                      && <>
                                        {JSON.parse(msg.commandMessage.data).token &&
                                          <CardSubtitle>
                                            Token: <a target="_blank" href={configuration[stage].Trustshare_link_escrow + JSON.parse(msg.commandMessage.data).token}>{JSON.parse(msg.commandMessage.data).token}</a>
                                          </CardSubtitle>
                                        }
                                        {JSON.parse(msg.commandMessage.data).status &&
                                          <CardSubtitle>
                                            Status: {JSON.parse(msg.commandMessage.data).status}
                                          </CardSubtitle>
                                        }
                                        {/* {JSON.parse(msg.commandMessage.data).paymentToken &&
                                    <CardSubtitle>
                                      PaymentToken: {JSON.parse(msg.commandMessage.data).paymentToken}
                                    </CardSubtitle>
                                  } */}
                                      </>
                                    }
                                    {msg.commandMessage.command == SowCommands.PAUSE_ESCROW_PAYMENT &&
                                      <>
                                        {JSON.parse(msg.commandMessage.data).reason &&
                                          <CardSubtitle tag="b">
                                            Reason: {t(`chat.SowCommands.${msg.commandMessage.command}_reason.${JSON.parse(msg.commandMessage.data).reason}`)}
                                          </CardSubtitle>
                                        }
                                      </>
                                    }
                                    {msg.commandMessage.command == SowCommands.SUBMIT ?
                                      <CardText className="mt-3">
                                        {newAttachments.some((file: any) => file.filename === configuration[stage].works_agreement_key) ?
                                          <Trans i18nKey={SowCommands.SUBMIT}>
                                            {"The seller submitted the "}
                                            <a target="_blank" href={newAttachments.find((file: any) => file.filename === configuration[stage].works_agreement_key).downloadUrl}>Statement of Work</a>
                                            {" to be checked and accepted by the buyer."}
                                          </Trans>
                                          : <Trans i18nKey={SowCommands.SUBMIT}>
                                            {"The seller submitted the "}
                                            {"Statement of Work"}
                                            {" to be checked and accepted by the buyer."}
                                          </Trans>
                                        }
                                      </CardText>
                                      : msg.commandMessage.command == SowCommands.CLAIM_MILESTONE_MET ?
                                        <CardText className="mt-3">
                                          <Trans i18nKey={SowCommands.CLAIM_MILESTONE_MET}>
                                            {newAttachments.find((file: any) => (file.filename === configuration[stage].deliverable_key)) && (newAttachments.find((file: any) => (file.filename === configuration[stage].deliverable_key)).downloadUrl).find((version: any) => version.versionId === JSON.parse(msg.commandMessage.data).VersionId) ?
                                              <>
                                                {"The seller claimed the milestone as met attaching the "}
                                                <a target="_blank" href={(newAttachments.find((file: any) => (file.filename === configuration[stage].deliverable_key)).downloadUrl).find((version: any) => version.versionId === JSON.parse(msg.commandMessage.data).VersionId).downloadUrl}>deliverable</a>.
                                              </>
                                              : <>
                                                {"The seller claimed the milestone as met attaching the "}
                                                {"deliverable."}
                                              </>

                                            }
                                            {/* {"\nThe buyer has 7 days starting from today to take an action, otherwise the system will accept the milestone and complete the payment."} */}
                                          </Trans>
                                        </CardText>
                                        : <CardText className="mt-3">
                                          {t(`chat.SowCommands.${msg.commandMessage.command}_info`)}
                                        </CardText>
                                    }
                                    {currentSow.status == SowStatus.SUBMITTED && msg.commandMessage.command == SowCommands.SUBMIT && currentSow.buyer === user.username &&
                                      <Row>
                                        <Col className="col-12 col-md-6 pt-2">
                                          <ActivityButton /* disabled={currentChosenArbitrator == ''} */ block color="primary" name={SowCommands.ACCEPT_AND_PAY}
                                            onClick={
                                              // !userAttributes.address ?
                                              //   () => {
                                              //     history.push('/profile')
                                              //     dispatch(NotificationActions.willShowNotification({ message: "Please complete your profile before accept and pay.", type: "info" }));
                                              //   }
                                              // : (currentSow.currency != "ALGO" && !algorandAccount.assets.some((accountAsset: any) => JSON.parse(accountAsset)["asset-id"] == assetsCurrencies.find((asset: any) => asset.assetName === currentSow.currency).assetIndex)) ?
                                              //   () => {
                                              //     dispatch(AssetCurrencyActions.willGoToAssetCurrencyPage({ address: userAttributes.public_key, history: history }));
                                              //     dispatch(AssetCurrencyActions.willSelectAssetCurrency({ asset: assetsCurrencies.find((asset: any) => asset.assetName === currentSow.currency).assetIndex }))
                                              //   }
                                              // : 
                                              toggleModalAcceptSow
                                            }
                                          >
                                            Accept and pay
                                    </ActivityButton>
                                        </Col>
                                        <Col className="col-12 col-md-6 pt-2">
                                          <ActivityButton block color="primary" name={SowCommands.REJECT}
                                            onClick={toggleModalReject}
                                          >Reject</ActivityButton>
                                        </Col>
                                      </Row>
                                    }
                                    {/* {(currentSow.status == SowStatus.ACCEPTED_PAID || currentSow.status == SowStatus.REVIEW_REQUIRED) && (msg.commandMessage.command == SowCommands.ACCEPT_AND_PAY || msg.commandMessage.command == SowCommands.REQUEST_REVIEW) && currentSow.seller === user.username &&
                                <Row>
                                  <Col>
                                    <ActivityButton block color="primary" name={SowCommands.CLAIM_MILESTONE_MET}
                                      onClick={toggleModalClaimMilestoneMet}
                                    >Claim milestone met</ActivityButton>
                                  </Col>
                                </Row>
                              } */}
                                    {currentSow.status == SowStatus.MILESTONE_CLAIMED && msg.commandMessage.command == SowCommands.CLAIM_MILESTONE_MET && currentSow.buyer === user.username &&
                                      <Row>
                                        <Col className="col-12 col-md-6 pt-2">
                                          <ActivityButton block color="primary" name={SowCommands.REQUEST_REVIEW}
                                            disabled={currentSow.numberReviews == 0}
                                            onClick={toggleModalRequestReview}
                                          >Ask review</ActivityButton>
                                        </Col>
                                        <Col className="col-12 col-md-6 pt-2">
                                          <ActivityButton block color="primary" name={SowCommands.ACCEPT_MILESTONE}
                                            onClick={toggleModalAcceptMilestone}
                                          >Accept</ActivityButton>
                                        </Col>
                                      </Row>
                                    }

                                  </>
                                  :
                                  <>
                                    {(msg.type == 'ATTACHMENT' && msg.attachmentMessage.type.startsWith('image')) &&
                                      <Row>
                                        <Col className="text-center">
                                          <img src={msg.attachmentMessage.downloadUrl} width="75%" alt="Attachment" className="img-fluid" />
                                        </Col>
                                      </Row>
                                    }
                                    {
                                      msg.attachmentMessage.key.split('/').pop().length > 20 ?
                                        msg.attachmentMessage.key.split('/').pop().substring(0, 16) + '... ' + msg.attachmentMessage.key.split('/').pop().substring(msg.attachmentMessage.key.split('/').pop().length - 4, msg.attachmentMessage.key.split('/').pop().length)
                                        : msg.attachmentMessage.key.split('/').pop()
                                    }
                                  </>
                              }
                            </>
                          }
                          notch={msg.commandMessage && msg.commandMessage.command == SowCommands.SYSTEM_SIGN ? false : true}
                          date={new Date(msg.createdAt)}
                          data={msg.type == 'ATTACHMENT' ?
                            {
                              uri: msg.attachmentMessage.downloadUrl,
                              status: {
                                click: true,
                                loading: 0,
                              }
                            }
                            : {}
                          }
                          onClick={(e: any) => {
                            msg.attachmentMessage && msg.attachmentMessage.downloadUrl &&
                              window.open(msg.attachmentMessage.downloadUrl);
                          }}
                          onDownload={(event: any) => {
                            window.open(msg.attachmentMessage.downloadUrl);
                          }}
                        />
                      </Col>

                      <Col className={user.username == msg.from ? "col-auto pr-4 d-none d-lg-block" : "ml-auto col-auto pr-4 d-none d-lg-block"}>
                        {user.username == msg.from &&
                          <Avatar
                            src={`${configuration[stage].host}/resources/${msg.from}/portrait?${Date.now()}`}
                            alt="Portrait"
                            size="small"
                            type="circle flexible"
                            onError={addDefaultSrc}
                          />
                        }
                      </Col>
                    </Row>
                  </>
                )
              })
            }
          </Card>
        </Col>
      </Row >
      <Row>
        <Col className="col-12 px-0">
          <InputChatElements
            inputStyle={{ overflow: 'auto', maxHeight: '300px' }}
            className="border"
            value={message}
            placeholder="Type or paste an image here..."
            multiline={true}
            onChange={(event: any) => {
              dispatch(ChatActions.willWriteMessage(event.target.value))
            }}
            onPaste={(event: any) => {
              var item = event.clipboardData.items[0];
              if (item.type.indexOf("image") === 0) {
                var blob = item.getAsFile();
                const filenameSplitted = blob.name.split('.');
                const myRenamedFile = new File([blob], filenameSplitted[0] + '_' + blob.lastModified + '.' + filenameSplitted[1], {
                  type: `image/${filenameSplitted[1]}`,
                });
                dispatch(SowActions.willPrepareUploadAttachment({ sow: currentSow, attachment: myRenamedFile, username: user.username, newAttachments: newAttachments, keyAttachment: null }))
              }
            }}
            inputRef={(ref: any) => inputRef = ref}
            rightButtons={
              <ActivityButton data-cy='sendMessage' type="submit" name="sendMessageChat" color="primary" block
                onClick={() => {
                  console.log('in onsubmit with: ', message)
                  message.trim() != "" && dispatch(ChatActions.willSendTextChat({ values: { message: message.trim() }, sow: currentSow }));
                  inputRef.value = ''
                }}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </ActivityButton>
            }
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Formik
            initialValues={{}}
            onSubmit={() => { }}
          >
            <>
              <Label for="attachments">
                Attachments
              {/* <CardSubtitle className="fs-5 text-muted" style={{ fontSize: 12 }}>
                Don't upload the deliverable here, but only while claiming milestone.
              </CardSubtitle> */}
              </Label>
              <SowAttachmentsInput currentSow={currentSow} />
            </>
          </Formik>
        </Col>
      </Row>

      {(currentSow.status === SowStatus.SUBMITTED || currentSow.status === SowStatus.WAITING_FOR_ESCROW_PAYMENT || currentSow.status === SowStatus.ACCEPTED_PAID) &&
        <AcceptAndPay modal={transactionModal[SowCommands.ACCEPT_AND_PAY]} toggle={toggleModalAcceptSow} />
      }
      {(currentSow.status == SowStatus.ACCEPTED_PAID || currentSow.status == SowStatus.REVIEW_REQUIRED || currentSow.status == SowStatus.MILESTONE_CLAIMED) &&
        <ClaimMilestoneMet modal={transactionModal[SowCommands.CLAIM_MILESTONE_MET]} toggle={toggleModalClaimMilestoneMet} />
      }
      {(currentSow.status === SowStatus.MILESTONE_CLAIMED || currentSow.status === SowStatus.WAITING_FOR_ESCROW_RELEASE || currentSow.status === SowStatus.PAYMENT_SENT) &&
        <AcceptMilestone modal={transactionModal[SowCommands.ACCEPT_MILESTONE]} toggle={toggleModalAcceptMilestone} />
      }
      <Reject modal={transactionModal[SowCommands.REJECT]} toggle={toggleModalReject} />
      <RequestReview modal={transactionModal[SowCommands.REQUEST_REVIEW]} toggle={toggleModalRequestReview} />
    </>
  )
}