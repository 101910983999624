export const algorandSendRawTxQuery =  
`mutation algorandSendRawTx(
  $tx: [TransactionInput]
) {
  algorandSendRawTx(
    tx: $tx
  ) {
    tx
    error
  }
}
`