import {
  CardText, CardTitle, Container, Jumbotron
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicyPage = () => {

  const { t, i18n } = useTranslation();

  return (
    <>
      <Container>
        <CardTitle className="h5 text-center font-weight-bold">
          Privacy Policy
          </CardTitle>
        <Jumbotron style={{height: "95%"}} className="d-flex justify-content-center">
          <iframe width="80%" height="100%" 
            src="https://docs.google.com/document/d/e/2PACX-1vScWcBn8c46ZkNaeC2wV-Uup5rA5Mo86AiQVN91lBtyWtmNQ6tSozVb6_82XMfiUQ/pub?embedded=true" />
        </Jumbotron>
      </Container>
    </>
  )
}