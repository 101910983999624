export const algorandGetTxParamsQuery =  
`query algorandGetTxParams{
  algorandGetTxParams {
    flatFee
    fee
    firstRound
    lastRound
    genesisID
    genesisHash
    error
  }
}
`