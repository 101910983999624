export const addArbitratorQuery =
  `mutation addArbitrator(
  $fee: ArbitratorFeeInput,
  $currency: Currency,
  $tags: [String]
) {
  addArbitrator(
    fee: $fee,
    currency: $currency,
    tags: $tags
  ) {
    fee {
      flat
      perc
    }
    currency
    tags
    requestId
  }
}
`