export const getArbitratorsListQuery =  `
query getArbitratorsList{
  listArbitrators{
    arbitrators {
      user
      enabled
      fee {
        flat
        perc
      }
      currency
      tags
      reputation
      given_name
      family_name
      public_key
      email
    }
    nextToken
  }
}
`