export const setBuyerOnceMutation = `
mutation setBuyerOnce($sow: ID!, $bankInfo: AWSJSON){
  setBuyerOnce(sow: $sow, bankInfo: $bankInfo){
    sow
    codeOfConduct
    currency
    buyer
    deadline
    description
    numberReviews
    price
    quantity
    tags
    termsOfService
    title
    createdAt
    updatedAt
    seller
    sowExpiration
    submittedDate
    licenseTermsOption
    licenseTermsNotes
  }
}
`