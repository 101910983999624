import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Row, Card, CardBody, CardTitle, Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter,
  ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
  FormGroup, Label, Input, Jumbotron, CardSubtitle, CardText, CustomInput
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { actions as SowActions, selectors as SowSelectors, SowCommands } from '../../store/slices/sow'
import { selectors as ArbitratorSelectors } from '../../store/slices/arbitrator'
import { actions as TransactionActions, selectors as TransactionSelectors } from '../../store/slices/transaction'
import { actions as NotificationActions } from '../../store/slices/notification'
import { actions as ProfileActions, selectors as ProfileSelectors } from '../../store/slices/profile'
import { selectors as AuthSelectors } from '../../store/slices/auth'
import { SowDetails } from '../sow/SowDetails'
import { ActivityButton } from '../common/ActivityButton';


export const Archive = ({ modal, toggle }: any) => {

  const dispatch = useDispatch();
  const user = useSelector(AuthSelectors.getUser)
  let saveMnemonicLS: any = localStorage.getItem('saveMnemonic')
  let saveMnemonicParsed = saveMnemonicLS ? JSON.parse(saveMnemonicLS) : undefined
  const saveMnemonicMy = saveMnemonicParsed ? saveMnemonicParsed[user.username] : undefined
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const currentSow = useSelector(SowSelectors.getCurrentSow)
  const currentChosenArbitrator = useSelector(ArbitratorSelectors.getCurrentChosenArbitrator)
  const transactionPage = useSelector(TransactionSelectors.getTransactionPage)
  const submitToken = useSelector(TransactionSelectors.getSubmitToken)
  const transactionError = useSelector(TransactionSelectors.getError)
  const params = useSelector(TransactionSelectors.getParams)
  const worksAgreementPdf = useSelector(SowSelectors.getWorksAgreementPdf)
  const algoSigner = useSelector(TransactionSelectors.getAlgoSigner)

  React.useEffect(() => {
    dispatch(TransactionActions.goToTransactionPage({ transactionPage: 1, sowCommand: SowCommands.ARCHIVE }))

    return () => {
      dispatch(TransactionActions.goToTransactionPage({ transactionPage: 0, sowCommand: SowCommands.ARCHIVE }))
    }
  }, [modal])

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl" backdrop={"static"} scrollable={true}>
      {transactionPage[SowCommands.ARCHIVE] == 0 &&
        <>
          <ModalHeader toggle={toggle}>Delete</ModalHeader>
          <ModalBody className="text-center">
            <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
          </ModalBody>
        </>
      }
      {transactionPage[SowCommands.ARCHIVE] == 1 &&
        <>
          <ModalHeader toggle={toggle}>Delete</ModalHeader>
          <ModalBody>
            <Jumbotron name="archive" id="archive">
              <CardText name="transactionConditions">{t('transaction.archive')}</CardText>
            </Jumbotron>
          </ModalBody>
          <ModalFooter>
            <ActivityButton data-cy='cancel' name="cancel" outline color="primary" onClick={() => {
              toggle()
            }}>Cancel</ActivityButton>
            <ActivityButton data-cy={"willArchive"} name="willArchive" color="primary" onClick={() => {
              dispatch(TransactionActions.willArchive({ sowCommand: SowCommands.ARCHIVE, sow: currentSow.sow, history: history }))
            }}>Delete</ActivityButton>
          </ModalFooter>
        </>
      }
      {transactionPage[SowCommands.ARCHIVE] == 2 &&
        <>
          <ModalHeader toggle={toggle}>Deleted</ModalHeader>
          <ModalBody>
            <Jumbotron>
              <CardText name="transactionConditions">{t('transaction.archiveCompleted')}</CardText>
            </Jumbotron>
          </ModalBody>
          <ModalFooter>
            <ActivityButton data-cy="closeReject" name="closeReject" color="primary" onClick={() => {
              toggle()
              history.push('/')
            }}
            >Close</ActivityButton>
          </ModalFooter>
        </>
      }
    </Modal >
  )
}