export const getSowsListSellerQuery =  `
query getSowsListSeller{
  listSowsAsSeller {
    sows {
      sow
      codeOfConduct
      currency
      deadline
      description
      numberReviews
      price
      quantity
      tags
      termsOfService
      title
      status
      buyer
      seller
      createdAt
      updatedAt
      messagesToReadBuyer
      messagesToReadSeller
      sowExpiration
      submittedDate
      signedMsig
      licenseTermsOption
      licenseTermsNotes
    }
    nextToken
  }
}
`