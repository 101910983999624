import React from 'react';
import { useHistory } from "react-router-dom";
import './App.scss';

import { Provider } from 'react-redux'
import { configureStore, history } from './store'
import { AppRouter } from './router'
import { NotificationLayer } from './notification'
import { LoadingLayer } from './loading'

function App() {

  let history = useHistory();
  console.log("APP history: ", history)

  return (
    <div>
      <Provider store={configureStore({})} >
        <NotificationLayer>
          <LoadingLayer>
            <AppRouter />
          </LoadingLayer>
        </NotificationLayer>
      </Provider>
    </div>
  );
}

export default App;
