import * as React from 'react';
import {
  Card, CardText, CardBody,
  CardTitle, CardSubtitle, Button,
  Container, FormGroup, Input, Label, FormFeedback,
  Col, Row, Jumbotron
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ActivityButton } from '../components/common/ActivityButton'
import { actions as AuthActions, selectors as AuthSelectors } from '../store/slices/auth'
import { selectors as UISelectors, actions as UIActions } from '../store/slices/ui'

const SignupSchema = Yup.object().shape({
  givenName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  familyName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  passwordConfirm: Yup.string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    })
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  emailConfirm: Yup.string()
    .test('email-match', 'Emails must match', function (value) {
      return this.parent.email === value
    })
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

export const SignupPage = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isLoading = useSelector(UISelectors.isLoading)
  const loginError = useSelector(AuthSelectors.getLoggedError);
  let history = useHistory();

  React.useEffect(() => {
    console.log('effect loginError: ', loginError);
  }, [loginError])

  React.useEffect(() => {
    // dispatch(UIActions.stopLoading())
  }, [])

  return (
    <>
      {!isLoading &&
        <Container>
          <CardTitle className="h5 text-center font-weight-bold">Signup Page</CardTitle>
          <CardSubtitle className="h6 mb-2 text-muted text-center">Hint: Insert Email e Password</CardSubtitle>
          <Formik
            initialValues={{
              email: '',
              emailConfirm: '',
              familyName: '',
              givenName: '',
              password: '',
              passwordConfirm: ''
            }}
            validationSchema={SignupSchema}
            validateOnBlur={true}
            onSubmit={values => {
              console.log('in onsubmit with: ', values)
              dispatch(AuthActions.willSignupUser({ email: values.email, password: values.password, family_name: values.familyName, given_name: values.givenName, history: history }));
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Card>
                <Form className="m-3">
                  <Row>
                    <Col className="col-12 col-md-6">
                      <FormGroup>
                        <Label for="givenName">Name</Label>
                        <Input invalid={errors.givenName && touched.givenName ? true : false} type="text" name="givenName" id="givenName" placeholder="name" tag={Field} />
                        {errors.givenName && touched.givenName ? (
                          <FormFeedback>{errors.givenName}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col className="col-12 col-md-6">
                      <FormGroup>
                        <Label for="familyName">Surname</Label>
                        <Input invalid={errors.familyName && touched.familyName ? true : false} type="text" name="familyName" id="familyName" placeholder="surname" tag={Field} />
                        {errors.familyName && touched.familyName ? (
                          <FormFeedback>{errors.familyName}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 col-md-6">
                      <FormGroup>
                        <Label for="email">Email Address</Label>
                        <Input invalid={errors.email && touched.email ? true : false} type="text" name="email" id="email" placeholder="email" tag={Field} />
                        {errors.email && touched.email ? (
                          <FormFeedback>{errors.email}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col className="col-12 col-md-6">
                      <FormGroup>
                        <Label for="emailConfirm">Confirm Email Address</Label>
                        <Input invalid={errors.emailConfirm && touched.emailConfirm ? true : false} type="text" name="emailConfirm" id="emailConfirm" placeholder="email confirm" tag={Field} />
                        {errors.emailConfirm && touched.emailConfirm ? (
                          <FormFeedback>{errors.emailConfirm}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 col-md-6">
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <Input invalid={errors.password && touched.password ? true : false} type="password" name="password" id="password" placeholder="password" tag={Field} />
                        {errors.password && touched.password ? (
                          <FormFeedback>{errors.password}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col className="col-12 col-md-6">
                      <FormGroup>
                        <Label for="passwordConfirm">Confirm Password</Label>
                        <Input invalid={errors.passwordConfirm && touched.passwordConfirm ? true : false} type="password" name="passwordConfirm" id="passwordConfirm" placeholder="password confirm" tag={Field} />
                        {errors.passwordConfirm && touched.passwordConfirm ? (
                          <FormFeedback>{errors.passwordConfirm}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-center">
                    <Col className="col-6 col-md-3">
                      <ActivityButton type="submit" name="signup" color="primary" block>Signup</ActivityButton>
                    </Col>
                  </Row>
                  <Row className="mt-3 d-flex justify-content-center">
                    <Col className="col-6 col-md-3">
                      <Button color="primary" block to="/login" outline tag={Link}>Login</Button>
                    </Col>
                  </Row>
                </Form>
              </Card>

            )}
          </Formik>
        </Container>
      }
    </>
  )
}