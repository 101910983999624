export const getArbitratorQuery =  `
query getArbitrator(
  $user: String
){
  getArbitrator(
    user: $user
  ) {
    user
    enabled
    fee {
      flat
      perc
    }
    currency
    tags
    reputation
    given_name
    family_name
    public_key
    email
    requestId
  }
}
`