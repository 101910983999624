export const algorandSendDeliverableTokenCreationTxQuery =  
`mutation algorandSendDeliverableTokenCreationTx(
  $sow: ID,
  $tx: [TransactionInput]
) {
  algorandSendDeliverableTokenCreationTx(
    sow: $sow,
    tx: $tx
  ) {
    assetId
    tx
    error
  }
}
`