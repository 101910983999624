export const updateArbitratorQuery =  `
mutation updateArbitrator(
  $enabled: Boolean,
  $fee: ArbitratorFeeInput,
  $currency: Currency,
  $tags: [String]
) {
  updateArbitrator(
    enabled: $enabled,
    fee: $fee,
    currency: $currency,
    tags: $tags
  ) {
    enabled
    fee {
      flat
      perc
    }
    currency
    tags
  }
}
 `