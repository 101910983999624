export const getSowFromLinkQuery =  `
query getSowFromLink(
  $sow: ID
){
  getSowFromLink(
    sow: $sow
  ) {
    sow
    codeOfConduct
    currency
    deadline
    description
    numberReviews
    price
    quantity
    tags
    termsOfService
    title
    status
    buyer
    seller
    createdAt
    updatedAt
    messagesToReadBuyer
    messagesToReadSeller
    sowExpiration
    submittedDate
    signedMsig
    licenseTermsOption
    licenseTermsNotes
  }
}
`