import {
  Row, Col, CardText, CardTitle, Container, Jumbotron
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

export const ContactUsPage = () => {

  const { t, i18n } = useTranslation();

  return (
    <>
      <Container>
        <CardTitle className="h5 text-center font-weight-bold">
          Contact Us
        </CardTitle>
        <Jumbotron className="m-3 p-3">
          <Row className="text-center">
            <Col>
              <CardText tag="h5">
                Contact support at:
              </CardText>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex justify-content-center">
              <CardText tag="h4">
                <a target="_blank" href="mailto:team@uniscrow.com">team@uniscrow.com</a>
              </CardText>
            </Col>
          </Row>
        </Jumbotron>
      </Container>
    </>
  )
}