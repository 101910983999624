export const setSignedMsigQuery =  `
mutation setSignedMsig(
  $sow: ID,
  $signedMsig: String
) {
  setSignedMsig(
    sow: $sow,
    signedMsig: $signedMsig
  ) {
    sow
    arbitrators
    codeOfConduct
    currency
    deadline
    description
    numberReviews
    price
    quantity
    tags
    termsOfService
    title
    status
    buyer
    seller
    arbitrator
    createdAt
    updatedAt
    messagesToReadBuyer
    messagesToReadSeller
    messagesToReadArbitrator
    sowExpiration
    submittedDate
    signedMsig
  }
}
 `