export const createSowQuery = `
mutation createSow{
  createSow {
    codeOfConduct
    currency
    seller
    buyer
    deadline
    description
    numberReviews
    price
    quantity
    tags
    termsOfService
    title
    sow
    createdAt
    updatedAt
    status
    licenseTermsOption
    licenseTermsNotes
  }
}
`