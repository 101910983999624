import * as React from 'react';
import {
  Card, CardText, CardBody, CardTitle, CardSubtitle,
  Button, Container, Col, Row, Tooltip, ListGroupItem, Jumbotron,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { configuration } from '../config'
import { StatementOfWorkSchema } from '../yupSchema/StatementOfWorkSchema'
import { actions as TransactionActions, selectors as TransactionSelectors } from '../store/slices/transaction'
import { actions as SowActions, selectors as SowSelectors, SowStatus, SowCommands } from '../store/slices/sow'
import { selectors as AuthSelectors } from '../store/slices/auth'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { actions as ArbitratorActions, selectors as ArbitratorSelectors } from '../store/slices/arbitrator'
import { actions as AssetCurrencyActions, selectors as AssetCurrencySelectors } from '../store/slices/assetCurrency'
import { actions as NotificationActions } from '../store/slices/notification'
import { ChatSow } from '../components/ChatSow'
import { ArbitratorDetailXS } from '../components/arbitrator/ArbitratorDetailXS'
import { ActivityButton } from '../components/common/ActivityButton'
import { RefreshButton } from '../components/common/RefreshButton'
import { FileButton } from '../components/common/FileButton'
import { SowDetails } from '../components/sow/SowDetails'
import { SowSummary } from '../components/sow/SowSummary'
import { SowAttachments } from '../components/sow/SowAttachments'
import { Archive } from '../components/transaction/Archive'
import { SubmitSow } from '../components/transaction/SubmitSow'
import { AcceptAndPay } from '../components/transaction/AcceptAndPay'
import { ClaimMilestoneMet } from '../components/transaction/ClaimMilestoneMet'
import { AcceptMilestone } from '../components/transaction/AcceptMilestone'
import { Reject } from '../components/transaction/Reject'
import { RequestReview } from '../components/transaction/RequestReview'
import { RaiseDispute } from '../components/transaction/RaiseDispute'

const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

function validateEmail(email: any) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const StatementOfWorkPage = () => {

  let { code }: any = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const currentSow = useSelector(SowSelectors.getCurrentSow)
  console.log("in statementOfWorkPage currentSow: ", currentSow)
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const algorandAccount = useSelector(ProfileSelectors.getAlgorandAccount)
  const assetsCurrencies = useSelector(AssetCurrencySelectors.getAssetsCurrencies)
  const currentArbitrators = useSelector(SowSelectors.getCurrentArbitrators);
  const currentChosenArbitrator = useSelector(ArbitratorSelectors.getCurrentChosenArbitrator)
  const newAttachments = useSelector(SowSelectors.getNewAttachments)
  const user = useSelector(AuthSelectors.getUser)
  const users = useSelector(ProfileSelectors.getUsers)
  const transactionModal = useSelector(TransactionSelectors.getTransactionModal)

  const toggleModalArchive = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.ARCHIVE }));
  const toggleModalSubmitSow = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.SUBMIT }));
  const toggleModalAcceptSow = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.ACCEPT_AND_PAY }));
  const toggleModalClaimMilestoneMet = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.CLAIM_MILESTONE_MET }));
  const toggleModalAcceptMilestone = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.ACCEPT_MILESTONE }));
  const toggleModalReject = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.REJECT }));
  const toggleModalRequestReview = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.REQUEST_REVIEW }));
  const toggleModalRaiseDispute = () => dispatch(TransactionActions.toggleTransactionModal({ sowCommand: SowCommands.RAISE_ESCROW_DISPUTE }));


  const [tooltipOpenAcceptAndPay, setTooltipOpenAcceptAndPay] = React.useState(false);
  const toggleAcceptAndPay = () => setTooltipOpenAcceptAndPay(!tooltipOpenAcceptAndPay);

  const [modalOpenSowDetails, setModalOpenSowDetails] = React.useState(false);
  const toggleModalSowDetails = () => setModalOpenSowDetails(!modalOpenSowDetails);

  React.useEffect(() => {
    console.log("in statementOfWorkPage currentSow: ", currentSow)
    dispatch(SowActions.willGetSow({ sow: code, history: history }))


  }, [])

  React.useEffect(() => {
    currentSow.status == SowStatus.SUBMITTED && dispatch(ArbitratorActions.selectingOneArbitrator())

    // if (currentSow.status == SowStatus.DRAFT && user.username == currentSow.buyer && !userAttributes.address) {
    //   history.push('/profile')
    //   dispatch(NotificationActions.willShowNotification({ message: "Please complete your profile in order to continue.", type: "info" }))
    // }
  }, [currentSow])

  return (
    <>
      {Object.keys(currentSow).length &&
        <Container>
          {/* <Card> */}
          <CardBody>
            <Row className="mb-3 align-items-center">
              <Col className="col-10 col-md-11">
                <CardTitle className="h5 text-center font-weight-bold">{currentSow.title}</CardTitle>
              </Col>
              <Col className="col-1 text-right">
                <RefreshButton data-cy='getSow' type="submit" name="getSow" color="primary"
                  onClick={() => {
                    console.log('in refreshSow with code: ', code)
                    dispatch(SowActions.willGetSow({ sow: code }))
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-lg-8 col-12">
                <CardSubtitle tag="h6" className="mb-2 text-muted text-right">Chat</CardSubtitle>
                <Card className="p-4">
                  <ChatSow currentSow={currentSow} />
                </Card>
              </Col>
              <Col className="col-lg-4 col-12">
                <Row>
                  <Col className="col-12">
                    <SowSummary />
                  </Col>
                </Row>
                {/* {currentSow.status == SowStatus.SUBMITTED && currentSow.buyer == user.username &&
                    <Row>
                      <Col className="col-12">
                        <CardSubtitle tag="h6" className="mb-2 text-muted text-center">Arbitrators</CardSubtitle>
                        <Jumbotron>
                          {currentArbitrators.map((element: any, index: any) => {
                            return (
                              <ListGroupItem data-cy={`selectArbitrator${element.given_name}`} className={currentChosenArbitrator == element.user ? 'border border-primary bg-light' : 'border'} key={index}
                                onClick={() => {
                                  console.log("selecting arbitrator: ", element)
                                }}>
                                <ArbitratorDetailXS arbitrator={element} index={index} />
                              </ListGroupItem>
                            )
                          })}
                        </Jumbotron>
                      </Col>
                    </Row>
                  } */}
                {(/* currentSow.status != SowStatus.DRAFT && */ currentSow.status != SowStatus.REJECTED && currentSow.status != SowStatus.EXPIRED &&
                  currentSow.status != SowStatus.MILESTONE_ACCEPTED && currentSow.status != SowStatus.SYSTEM_SIGNED &&
                  currentSow.status != SowStatus.WAITING_FOR_ESCROW_DISPUTE
                ) &&
                  <Row>
                    <Col className="col-12">
                      <CardSubtitle tag="h6" className="m-2 text-muted text-right">Special commands</CardSubtitle>
                      <Card className="p-4">
                        {currentSow.arbitrator && currentSow.status == SowStatus.DISPUTED &&
                          <CardText className="text-muted text-center">
                            {t('chat.emptyComandsWithoutDate', { given_name: users[currentSow.arbitrator].given_name ? users[currentSow.arbitrator].given_name : "", family_name: users[currentSow.arbitrator].family_name ? users[currentSow.arbitrator].family_name : "" })}
                          </CardText>
                        }
                        {currentSow.status == SowStatus.PAYMENT_SENT &&
                          <CardText className="text-muted text-center">
                            {"Awaiting payment to complete by 7 days starting from the acceptance date by the buyer."}
                          </CardText>
                        }
                        {currentSow.seller == user.username &&
                          <>
                            {currentSow.status == SowStatus.DRAFT &&
                              <ActivityButton data-cy={SowCommands.CLAIM_MILESTONE_MET} block color="primary" name={SowCommands.CLAIM_MILESTONE_MET}
                                onClick={() => {
                                  StatementOfWorkSchema.validate(currentSow)
                                    .then(function (value) {
                                      // console.log("values then: ", value)
                                      toggleModalSubmitSow()
                                    })
                                    .catch(function (err) {
                                      // console.log("values catch: ", err)
                                      dispatch(NotificationActions.willShowNotification({ message: "Please complete the Statement of Work before submit.", type: "info" }));
                                      history.push('/create-statement-of-work')
                                    });
                                }}
                              >Submit</ActivityButton>
                            }
                            {currentSow.seller == user.username && currentSow.status == SowStatus.DRAFT &&
                              <ActivityButton color="danger" block onClick={toggleModalArchive} outline>Delete</ActivityButton>
                            }
                            {currentSow.status == SowStatus.SUBMITTED &&
                              <CardText className="text-muted text-center">
                                {t('chat.emptyComandsWithDate', { 
                                  given_name: currentSow.buyer !== "from_widget" || currentSow.buyer !== "not_set" ? "Buyer not set" : users[currentSow.buyer].given_name, 
                                  family_name: currentSow.buyer !== "from_widget" || currentSow.buyer !== "not_set" ? "Buyer not set" : users[currentSow.buyer].family_name, 
                                  date: new Date(currentSow.sowExpiration).toLocaleDateString() })}
                              </CardText>
                            }
                            {(currentSow.status == SowStatus.ACCEPTED_PAID || currentSow.status == SowStatus.REVIEW_REQUIRED) &&
                              <ActivityButton data-cy={SowCommands.CLAIM_MILESTONE_MET} block color="primary" name={SowCommands.CLAIM_MILESTONE_MET}
                                onClick={toggleModalClaimMilestoneMet}
                              >Claim milestone met</ActivityButton>
                            }
                            {currentSow.status == SowStatus.MILESTONE_CLAIMED &&
                              <CardText className="text-muted text-center">
                                {/* {t('chat.emptyComandsWithDate', {given_name: users[currentSow.buyer].given_name, family_name: users[currentSow.buyer].family_name, date: new Date(currentSow.).toLocaleDateString()})} */}
                                {t('chat.emptyComandsWithoutDate', { given_name: users[currentSow.buyer].given_name ? users[currentSow.buyer].given_name : "", family_name: users[currentSow.buyer].family_name ? users[currentSow.buyer].family_name : "" })}
                              </CardText>
                            }
                          </>
                        }
                        {currentSow.buyer == user.username &&
                          <>
                            {currentSow.status == SowStatus.DRAFT &&
                              <CardText className="text-muted text-center">
                                Discuss the Statement of Work with the seller.
                              </CardText>
                            }
                            {currentSow.status == SowStatus.SUBMITTED &&
                              <>
                                <ActivityButton data-cy={SowCommands.ACCEPT_AND_PAY} /* disabled={currentChosenArbitrator == ''} */ block color="primary" name={SowCommands.ACCEPT_AND_PAY}
                                  onClick={
                                    // !userAttributes.address ?
                                    //   () => {
                                    //     history.push('/profile')
                                    //     dispatch(NotificationActions.willShowNotification({ message: "Please complete your profile before accept and pay.", type: "info" }));
                                    //   }
                                    // : (currentSow.currency != "ALGO" && !algorandAccount.assets.some((accountAsset: any) => JSON.parse(accountAsset)["asset-id"] == assetsCurrencies.find((asset: any) => asset.assetName === currentSow.currency).assetIndex)) ?
                                    //   () => {
                                    //     dispatch(AssetCurrencyActions.willGoToAssetCurrencyPage({ address: userAttributes.public_key, history: history }));
                                    //     dispatch(AssetCurrencyActions.willSelectAssetCurrency({ asset: assetsCurrencies.find((asset: any) => asset.assetName === currentSow.currency).assetIndex }))
                                    //   }
                                    // : 
                                    toggleModalAcceptSow
                                  }
                                >
                                  Accept and pay
                                  {/* <span id={SowCommands.ACCEPT_AND_PAY}>Accept and pay</span>
                                    <Tooltip placement="top" isOpen={tooltipOpenAcceptAndPay} target={SowCommands.ACCEPT_AND_PAY} toggle={currentChosenArbitrator == '' ? toggleAcceptAndPay : () => { }}>
                                      Please choose an arbitrator
                                    </Tooltip> */}
                                </ActivityButton>
                              </>
                            }
                            {currentSow.status == SowStatus.ACCEPTED_PAID &&
                              <CardText className="text-muted text-center">
                                {t('chat.emptyComandsWithDate', { given_name: users[currentSow.seller].given_name, family_name: users[currentSow.seller].family_name, date: new Date(currentSow.deadline).toLocaleDateString() })}
                              </CardText>
                            }
                            {currentSow.status == SowStatus.MILESTONE_CLAIMED &&
                              <ActivityButton data-cy={SowCommands.REQUEST_REVIEW} block color="primary" name={SowCommands.REQUEST_REVIEW}
                                disabled={currentSow.numberReviews == 0}
                                onClick={toggleModalRequestReview}
                              >Ask review</ActivityButton>
                            }
                            {(currentSow.status == SowStatus.SUBMITTED) &&
                              <ActivityButton data-cy={SowCommands.REJECT} block color="primary" name={SowCommands.REJECT}
                                onClick={toggleModalReject}
                              >Reject</ActivityButton>
                            }
                            {currentSow.status == SowStatus.MILESTONE_CLAIMED &&
                              <ActivityButton data-cy={SowCommands.ACCEPT_MILESTONE} block color="primary" name={SowCommands.ACCEPT_MILESTONE}
                                onClick={toggleModalAcceptMilestone}
                              >Accept</ActivityButton>
                            }
                            {currentSow.status == SowStatus.MILESTONE_CLAIMED &&
                              <CardText className="text-muted text-center">
                                {t('chat.buyerMilestoneClaimedInfo')}
                              </CardText>
                            }
                            {currentSow.status == SowStatus.REVIEW_REQUIRED &&
                              <CardText className="text-muted text-center">
                                {t('chat.emptyComandsWithoutDate', { given_name: users[currentSow.seller].given_name, family_name: users[currentSow.seller].family_name })}
                              </CardText>
                            }
                          </>
                        }
                        {currentSow.status == SowStatus.WAITING_FOR_ESCROW_PAYMENT &&
                          <CardText className="text-muted text-center">
                            The escrow is waiting to receive the payment.
                          </CardText>
                        }
                        {currentSow.status == SowStatus.WAITING_FOR_ESCROW_RELEASE &&
                          <CardText className="text-muted text-center">
                            The escrow is releasing the payment.
                          </CardText>
                        }
                        {currentSow.status == SowStatus.ESCROW_DISPUTE_RAISED &&
                          <CardText className="text-muted text-center">
                            href={configuration[stage].Trustshare_link}
                            The dispute has been escalated to the resolution center.
                            Click <a target="_blank" href={configuration[stage].Trustshare_link}>here</a> to see how the dispute resolution works.
                          </CardText>
                        }
                        {currentSow.status == SowStatus.ESCROW_DISPUTE_RESOLVED &&
                          <CardText className="text-muted text-center">
                            The escrow resolved the dispute.
                          </CardText>
                        }
                        {(currentSow.status == SowStatus.ACCEPTED_PAID || currentSow.status == SowStatus.MILESTONE_CLAIMED || currentSow.status == SowStatus.REVIEW_REQUIRED) &&
                          <ActivityButton block color="warning" name={SowCommands.RAISE_ESCROW_DISPUTE}
                            onClick={toggleModalRaiseDispute}
                          >Open dispute</ActivityButton>
                        }
                      </Card>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col className="col-12">
                    <SowAttachments />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          {/* </Card> */}
        </Container>
      }

      <Archive modal={transactionModal[SowCommands.ARCHIVE]} toggle={toggleModalArchive} />
      <SubmitSow modal={transactionModal[SowCommands.SUBMIT]} toggle={toggleModalSubmitSow} />
      {
        (currentSow.status === SowStatus.SUBMITTED || currentSow.status === SowStatus.WAITING_FOR_ESCROW_PAYMENT || currentSow.status === SowStatus.ACCEPTED_PAID) &&
        <AcceptAndPay modal={transactionModal[SowCommands.ACCEPT_AND_PAY]} toggle={toggleModalAcceptSow} />
      }
      {
        (currentSow.status == SowStatus.ACCEPTED_PAID || currentSow.status == SowStatus.REVIEW_REQUIRED || currentSow.status == SowStatus.MILESTONE_CLAIMED) &&
        <ClaimMilestoneMet modal={transactionModal[SowCommands.CLAIM_MILESTONE_MET]} toggle={toggleModalClaimMilestoneMet} />
      }
      {
        (currentSow.status === SowStatus.MILESTONE_CLAIMED || currentSow.status === SowStatus.WAITING_FOR_ESCROW_RELEASE || currentSow.status === SowStatus.PAYMENT_SENT) &&
        <AcceptMilestone modal={transactionModal[SowCommands.ACCEPT_MILESTONE]} toggle={toggleModalAcceptMilestone} />
      }
      <Reject modal={transactionModal[SowCommands.REJECT]} toggle={toggleModalReject} />
      <RequestReview modal={transactionModal[SowCommands.REQUEST_REVIEW]} toggle={toggleModalRequestReview} />
      <RaiseDispute modal={transactionModal[SowCommands.RAISE_ESCROW_DISPUTE]} toggle={toggleModalRaiseDispute} />
    </>
  )
}