export const putProfileDataQuery =  `
mutation putProfileData(
  $name: String, 
  $value: String
) {
    putProfileData(
      name: $name, 
      value: $value
    ) {
      name
      value
    }
}
 `