import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

Sentry.init({ dsn: "https://4bddc88e380f45658bc157b4e37f7299@logs.radixcloud.org/5" });

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
