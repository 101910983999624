import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card, CardText, CardBody,
  CardTitle, CardSubtitle, Button, Container,
  FormText, FormGroup, Input, Label, FormFeedback,
  Col, Row, Jumbotron, InputGroup, InputGroupButtonDropdown,
  DropdownToggle, DropdownMenu, DropdownItem,
  ListGroupItem, ListGroupItemHeading, Spinner
} from 'reactstrap';
import { useFormikContext } from 'formik';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone'

import { actions as SowActions, selectors as SowSelectors, SowStatus } from '../store/slices/sow'
import { selectors as AuthSelectors } from '../store/slices/auth'
import { FileButton } from './common/FileButton';

export const SowAttachmentsInput = ({ currentSow, keyAttachment }: any) => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const attachments = useSelector(SowSelectors.getAttachments);
  const newAttachments = useSelector(SowSelectors.getNewAttachments);
  const { values, setFieldValue } = useFormikContext();
  const user = useSelector(AuthSelectors.getUser)

  const [attachmentMaxSize, setAttachmentMaxSize] = React.useState(false);
  const [dropzoneClassName, setDropzoneClassName] = React.useState("p-3 rounded text-muted");

  return (
    <>
      <FormGroup>
        <Dropzone
          multiple={false}
          onDragEnter={() => setDropzoneClassName("p-3 rounded text-muted border-primary font-weight-bold")}
          onDragLeave={() => setDropzoneClassName("p-3 rounded text-muted")}
          onDropAccepted={() => setDropzoneClassName("p-3 rounded text-muted border-info")}
          onDropRejected={() => setDropzoneClassName("p-3 rounded text-muted border-danger")}
          onDrop={(files) => {
            console.log("onDrop", files)
            if (files.length && files[0].size <= 104857600) {
              setAttachmentMaxSize(false)
              dispatch(SowActions.willPrepareUploadAttachment({ sow: currentSow, attachment: files[0], username: user.username, newAttachments: newAttachments, keyAttachment: keyAttachment }))
            }
            else {
              setAttachmentMaxSize(true)
            }
          }}>
          {({ getRootProps, getInputProps }) => (
            <section className={dropzoneClassName} style={{ borderStyle: "dotted", borderWidth: 2 }}>
              <CardSubtitle className={attachmentMaxSize ? "fs-2 text-danger" : "fs-2 text-muted"} style={{ fontSize: 10 }}>
                one file at a time, max 100 MB per file
              </CardSubtitle>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <CardText>Drop files here or <a className="text-primary">browse</a>.</CardText>
              </div>
            </section>
          )}
        </Dropzone>
      </FormGroup>
      {currentSow.status == SowStatus.DRAFT && newAttachments.map((att: any, index: any) => {
        return (
          keyAttachment === att.filename &&
          <ListGroupItem data-cy="attachmentsSow" key={index}>
            <ListGroupItemHeading>
              <Button close onClick={() => {
                dispatch(SowActions.willDeleteAttachment({ sow: currentSow, attachment: att }))
              }} />
              <FileButton file={att} />
            </ListGroupItemHeading>
          </ListGroupItem>
        )
      })}
    </>
  )
}